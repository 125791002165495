import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

function HelpCenter() {

  const location = useLocation();

  return (
    <div className="">
      <ul className='flex gap-4 text-gray-500 mb-7 htabs'>
        <li><NavLink to="/help" className={location.pathname === "/help" ? "nav_link" : "text-gray-800"}>All Request</NavLink></li>
        <li><NavLink to="/help/pending" className="nav_link">Pending</NavLink></li>
        <li><NavLink to="/help/resolved" className="nav_link">Resolved</NavLink></li>
      </ul>
    </div>
  )
}

export default HelpCenter
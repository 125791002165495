import React, { useState } from 'react'
import MainLayout from '../../components/layouts/MainLayout'
import { FiArrowLeft, FiEye, FiPhone, FiUserCheck } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthContext } from '../../hooks/useAuthContext';
import dateFormat from 'dateformat';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader'


const AgentView = () => {

    const { id } = useParams();
    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();

    const [page, setPage] = useState(1);
    const [pageInput, setPageInput] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [leadDetails, setLeadDetails] = useState(null);

    // get all list of agents
    const { data: agentDetails, isLoading, isError } = useQuery(["AgentDetails", page], () => {
        return axios.get(`${baseURL}admin/agents/${id}/show?page=${page}`, {
            headers: { 'Authorization': `Bearer ${user}` }
        }).then((res) => res.data)
    });

    // Get lead details and display it on a modal
    const getLeadDetails = async (leadId) => {
        if (leadId) {
            setLoading(true);
            setError(null);
            await axios.get(`${baseURL}admin/agents/leads/${leadId}/show`, {
                headers: {
                    'Authorization': `Bearer ${user}`
                }
            }).then((response) => {
                setLeadDetails(response.data);
                setLoading(false);
            }
            ).catch((error) => {
                setLoading(false);
                setError(error.message);
            })
        }

    }

    // get last page value
    const lastPage = agentDetails?.leads?.last_page;

    // Handle pagination
    const handlePagination = (direction) => {
        if (page < lastPage && direction === "next") {
            setPage(page + 1);

        }

        if (direction === "prev" && page > 1) {
            setPage(page - 1);

        }

        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }

    // Handle page position
    const handleGotoPage = () => {
        if (pageInput && pageInput <= lastPage) {
            setPage(pageInput);
        }
    }

    return (
        <MainLayout>
            <div className='flex justify-between items-center mb-6'>
                <Link className='text-[14px] font-semibold text-gray-600 flex items-center gap-2' to="/agents">
                    <FiArrowLeft className='text-md' />
                    <span>Go back</span>
                </Link>
            </div>

            <div className="w-full min-h-[83vh] h-full  bg-white rounded-md py-5 px-5">
                {isLoading &&
                    <div className="flex items-center justify-center w-full h-[83vh] min-h-full">
                        <CircularPreloader />
                    </div>}

                {!isLoading && <>
                    {/* User details */}
                    <div className=" flex flex-row gap-3 items-center">
                        <div className="flex gap-[10px] items-center">
                            <div className="w-16 h-16 bg-blue-200 rounded-full flex items-center justify-center">
                                <span className='text-[18px] font-semibold text-gray-600 uppercase'>
                                    {agentDetails?.agent?.first_name?.slice(0, 1)}{agentDetails?.agent?.last_name?.slice(0, 1)}
                                </span>
                            </div>
                            <div className=" border-r-[1px] pr-9 border-gray-200">
                                <p className='text-gray-500 text-sm font-semibold'>{agentDetails?.agent?.first_name} {agentDetails?.agent?.last_name}</p>
                                <span className='text-xs font-medium text-gray-400'>{agentDetails?.agent?.email}</span>
                            </div>
                        </div>

                        <div className="flex flex-col gap-1 pl-6">
                            <div className=" flex gap-3 items-center text-gray-500">
                                <FiPhone />
                                <span className='text-sm'>Mobile : {agentDetails?.agent?.mobile}</span>
                            </div>

                            <div className="flex gap-3 items-center text-gray-500">
                                <FiUserCheck />
                                <span className='text-sm'>Code : {agentDetails?.agent?.agent_code}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex border-b-[1px] pb-4 border-gray-100 mt-12 gap-2 items-center">
                        <span className='font-semibold text-gray-600'>List of data collection</span>
                        <div className='py-1 px-2 bg-green-100 
                    rounded-md flex items-center justify-center 
                    text-green-600 text-sm font-semibold'>{agentDetails?.leads?.total}</div>
                    </div>

                    {/* List of data collections */}
                    <div className="overflow-x-auto">
                        <table className="table w-full">
                            <thead>
                                {agentDetails?.leads?.data.length > 0 && <tr className='text-gray-500 text-xs'>
                                    <th>User</th>
                                    <th>Code</th>
                                    <th>Phone Number</th>
                                    <th>Data Count</th>
                                    <th>Registration Date</th>
                                    <th>Action</th>
                                </tr>}
                            </thead>
                            <tbody>
                                {agentDetails?.leads?.data.map((lead) => (
                                    <tr key={lead?.id}>
                                        <td>
                                            <div className="flex gap-[10px]">
                                                <div className="w-11 h-11 bg-gray-200 rounded-full flex items-center justify-center">
                                                    <span className='text-[12px] font-semibold text-gray-600 uppercase'>
                                                        {lead?.name?.slice(0, 1)}
                                                    </span>
                                                </div>
                                                <div className="">
                                                    <p className='text-gray-500 text-sm font-semibold'>{lead?.name}</p>
                                                    <span className='text-xs font-medium text-gray-400'>{lead?.email}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span className='text-gray-500 font-medium text-sm'>{lead?.agent_code}</span></td>
                                        <td><span className='text-gray-500 font-medium text-sm'>{lead?.mobile}</span></td>
                                        <td>
                                            <span className=' text-green-700 font-medium'>{lead?.gender}</span>
                                        </td>
                                        <td><span className='text-gray-500 font-medium text-sm'>{dateFormat(lead?.created_at, " mmm dS, yyyy,  h:MM TT")}</span></td>
                                        <td>
                                            <label htmlFor="my-modal-4" className='flex gap-2 items-center' onClick={() => getLeadDetails(lead?.id)}>
                                                <FiEye size="1rem" className='text-gray-500' />
                                                <span className='text-gray-500 font-medium text-sm cursor-pointer'>View</span>
                                            </label>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>

                    {/* Empty state */}

                    {agentDetails?.leads?.total < 1 && <div className="w-full flex flex-col justify-center items-center gap-5 min-h-[55vh]">
                        <p className='text-xl font-semibold text-gray-500'>This agent is yet to get a lead</p>

                    </div>}

                </>}


                {/* Pagination */}

                {agentDetails && lastPage > 1 && <div className="flex gap-2 mt-6 items-center">
                    {page !== 1 && <button
                        className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-40 disabled:cursor-not-allowed'
                        disabled={page === 1}
                        onClick={() => { handlePagination("prev") }}
                    >
                        Previous
                    </button>}

                    {page < lastPage && <button
                        className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
                        disabled={page === lastPage}
                        onClick={() => { handlePagination("next") }}
                    >
                        Next
                    </button>}

                    <span className='text-gray-500 text-xs'>{`Page ${page} of ${lastPage}`}</span>

                    <input type="text" placeholder="Page" className="input input-bordered input-sm w-16 "
                        onChange={(e) => setPageInput(Math.ceil(e.target.value))}
                    />

                    <button className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
                        onClick={() => handleGotoPage()}
                        disabled={pageInput > lastPage}
                    >
                        Go
                    </button>
                </div>}

            </div>

            {/* Leads details */}

            {/* Put this part before </body> tag */}
            <input type="checkbox" id="my-modal-4" className="modal-toggle" />
            <label htmlFor="my-modal-4" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    {!loading && <>
                        <label htmlFor="my-modal-4" className="btn btn-xs btn-circle absolute right-5 top-5">✕</label>
                        <h3 className="text-lg font-bold mb-6">{leadDetails?.name} Details</h3>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>Full name</span>
                            <span className='text-gray-600'>{leadDetails?.name}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>Email address</span>
                            <span className='text-gray-600'>{leadDetails?.email}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>Age</span>
                            <span className='text-gray-600'>{leadDetails?.age} years old</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>Gender</span>
                            <span className='text-gray-600'>{leadDetails?.gender}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>Marital Status</span>
                            <span className='text-gray-600'>{leadDetails?.marital_status}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>Occupation</span>
                            <span className='text-gray-600'>{leadDetails?.occupation}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>State of Origin</span>
                            <span className='text-gray-600'>{leadDetails?.home_state}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>City of residence</span>
                            <span className='text-gray-600'>{leadDetails?.home_city}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>Destination city</span>
                            <span className='text-gray-600'>{leadDetails?.destination_city}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
                            <span className='text-gray-500'>Travel Frequency </span>
                            <span className='text-gray-600'>{leadDetails?.travel_duration}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3 ">
                            <span className='text-gray-500 w-2/3'>Have you ever spent on accommodation
                                when you're travelling?
                            </span>
                            <span className='text-gray-600 w-1/3 text-right'>{leadDetails?.spent_on_accommodation}</span>
                        </div>

                        <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3 ">
                            <span className='text-gray-500 w-2/3'>How much would you spend on accommodation</span>
                            <span className='text-gray-600 w-1/3 text-right'>{leadDetails?.living_cost}</span>
                        </div>

                        <div className="flex justify-between items-center py-3 ">
                            <span className='text-gray-500 w-2/3'>How do you pay for the accommodation?</span>
                            <span className='text-gray-600 w-1/3 text-right'>{leadDetails?.accommodation_payment_mode}</span>
                        </div>

                    </>}

                    {loading && <div className='flex items-center justify-center w-full h-96'>
                        <CircularPreloader />
                    </div>}

                </label>
            </label>

        </MainLayout>
    )
}

export default AgentView

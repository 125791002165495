import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

function Transactions() {

  const location =  useLocation();

  return (
    <div className="">
      <ul className='flex gap-4 text-gray-500 mb-7 htabs'>
        <li><NavLink to="/transactions" className={location.pathname === "/transactions" ? "nav_link" : "text-gray-800"}>All Transactions</NavLink></li>
        <li><NavLink to="/transactions/payable" className="nav_link">Payable</NavLink></li>
        <li><NavLink to="/transactions/payout" className="nav_link">Payouts</NavLink></li>
      </ul>
    </div>
  )
}

export default Transactions
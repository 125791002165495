import React, { useState } from 'react'
import MainLayout from '../../components/layouts/MainLayout';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../hooks/useAuthContext';
import { FiEye } from 'react-icons/fi';
import dateFormat from 'dateformat';
import { useNavigate } from 'react-router-dom';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';


function Agents() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [page, setPage] = useState(1);
    const [pageInput, setPageInput] = useState();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required('Required'),
            lastname: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email format').required('Required'),
            phone: Yup.string().required('Required'),
        }),
        onSubmit: (values, { resetForm }) => {
            const formData = new FormData();

            formData.append("first_name", values.firstname);
            formData.append("last_name", values.lastname);
            formData.append("email", values.email);
            formData.append("mobile", values.phone);

            handleAgent(formData);
            resetForm();
        }
    });


    // Create an agent api call
    const handleAgent = async (formData) => {
        setLoading(true);
        setError("");

        await axios.post(`${baseURL}admin/agents/store`, formData, {
            headers: { 'Authorization': `Bearer ${user}` }
        }).then((res) => {
            if (res) {
                setLoading(false);
                document.getElementById('my-modal-4').checked = false;
                return res.data;
            }
        }).catch((error) => {
            console.log(error.message);
            setError(error);
            setLoading(false);
        })
    }

    // get all list of agents

    const { data: getAgents, isLoading } = useQuery(["GetAgents", page], () => {
        return axios.get(`${baseURL}admin/agents?page=${page}`, {
            headers: { 'Authorization': `Bearer ${user}` }
        }).then((res) => res.data);
    });

    // get last page value
    const lastPage = getAgents?.last_page;


    // Handle pagination
    const handlePagination = (direction) => {
        if (page < lastPage && direction === "next") {
            setPage(page + 1);

        }

        if (direction === "prev" && page > 1) {
            setPage(page - 1);

        }

        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }

    // Handle page position
    const handleGotoPage = () => {
        if (pageInput && pageInput <= lastPage) {
            setPage(pageInput);
        }
    }


    const colors = []
    return (
        <MainLayout>

            {isLoading && <div className="flex items-center justify-center w-full h-[83vh] min-h-full">
                <CircularPreloader />
            </div>}

            {!isLoading && <div className='flex justify-between items-center mb-6'>
                <h1 className='text-[18px] font-semibold text-gray-600'>List of agents</h1>
                <label htmlFor="my-modal-4"
                    className='bg-blue-700 py-2 px-6 text-white rounded-md transition hover:bg-blue-600 font-medium hover:shadow-sm cursor-pointer'
                >
                    Create an agent
                </label>
            </div>}

            {!isLoading && <div className="w-full h-[83vh] min-h-full  bg-white rounded-md py-2 px-2">

                <div className="overflow-x-auto">
                    <table className="table w-full">
                        <thead>
                            <tr className='text-gray-500 text-xs'>
                                <th>User</th>
                                <th>Code</th>
                                <th>Phone Number</th>
                                <th>Data Count</th>
                                <th>Registration Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getAgents?.data.map((agent) => (
                                <tr key={agent?.id}>
                                    <td>
                                        <div className="flex gap-[10px]">
                                            <div className="w-11 h-11 bg-gray-200 rounded-full flex items-center justify-center">
                                                <span className='text-[12px] font-semibold text-gray-600 uppercase'>
                                                    {agent?.first_name.slice(0, 1)}{agent?.last_name.slice(0, 1)}
                                                </span>
                                            </div>
                                            <div className="">
                                                <p className='text-gray-500 text-sm font-semibold'>{agent?.first_name} {agent?.last_name}</p>
                                                <span className='text-xs font-medium text-gray-400'>{agent?.email}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span className='text-gray-500 font-medium text-sm'>{agent?.agent_code}</span></td>
                                    <td><span className='text-gray-500 font-medium text-sm'>{agent?.mobile}</span></td>
                                    <td>
                                        <span className='py-1 px-5 rounded bg-green-100 text-green-700 font-medium'>{agent?.leads_count}</span>
                                    </td>
                                    <td><span className='text-gray-500 font-medium text-sm'>{dateFormat(agent?.created_at, " mmm dS, yyyy,  h:MM TT")}</span></td>
                                    <td>
                                        <div className='flex gap-2 items-center' onClick={() => navigate(`/agents/view/${agent?.id}`)}>
                                            <FiEye size="1rem" className='text-gray-500' />
                                            <span className='text-gray-500 font-medium text-sm cursor-pointer'>View</span>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                {/* Empty state for agents */}
                {getAgents?.total < 1 && <div className="w-full flex flex-col justify-center items-center gap-5 min-h-[85vh]">
                    <p className='text-2xl font-semibold text-gray-700'>You are yet to create an agent</p>

                    <label htmlFor="my-modal-4"
                        className='bg-blue-700 py-4 px-6 text-white rounded-md transition hover:bg-blue-600 font-medium hover:shadow-sm cursor-pointer'
                    >
                        Create an agent
                    </label>
                </div>}

                {/* Add agent modal */}
                <div className="">
                    <input type="checkbox" id="my-modal-4" className="modal-toggle" />
                    <label htmlFor='my-modal-4' className="modal cursor-pointer">
                        <label htmlFor="" className="modal-box relative">
                            <label htmlFor="my-modal-4" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                            <h3 className="text-lg font-bold mb-10">Add new agent</h3>

                            <form onSubmit={formik.handleSubmit}>

                                <div className='mb-6'>
                                    <input
                                        type="text"
                                        name="firstname"
                                        placeholder="First name"
                                        className="input input-bordered w-full "
                                        onChange={formik.handleChange}
                                        value={formik.values.firstname}
                                    />
                                    {formik.errors.firstname && formik.touched.firstname && <div className='text-red-500 text-sm'>{formik.errors.firstname}</div>}
                                </div>

                                <div className="mb-6">
                                    <input
                                        type="text"
                                        name="lastname"
                                        placeholder="Last name"
                                        className="input input-bordered w-full"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastname}
                                    />
                                    {formik.errors.lastname && formik.touched.lastname && <div className='text-red-500 text-sm'>{formik.errors.lastname}</div>}
                                </div>

                                <div className="mb-6">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email address"
                                        className="input w-full input-bordered"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    {formik.errors.email && formik.touched.email && <div className='text-red-500 text-sm'>{formik.errors.email}</div>}
                                </div>

                                <div className="mb-6">
                                    <input
                                        type="text"
                                        name="phone"
                                        placeholder="Phone number"
                                        className="input w-full input-bordered"
                                        onChange={formik.handleChange}
                                        value={formik.values.phone}
                                    />
                                    {formik.errors.phone && formik.touched.phone && <div className='text-red-500 text-sm'>{formik.errors.phone}</div>}
                                </div>


                                <button
                                    type='submit'
                                    className='bg-blue-700 py-3 px-6 text-white rounded-md transition hover:bg-blue-600 
                                font-medium hover:shadow-sm cursor-pointer disabled:bg-blue-200 disabled:cursor-not-allowed'
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    Add agent
                                </button>
                            </form>

                        </label>
                    </label>
                </div>

                {/* V */}


            </div>}

        </MainLayout>
    )
}

export default Agents

import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

function Hostels() {

  const location = useLocation();

  return (

    <div className="">
      <ul className='flex gap-4 text-gray-500 mb-7 htabs'>
        <li><NavLink to="/hostels" className={location.pathname === "/hostels"? "nav_link": "text-gray-800"}>All Hostels</NavLink></li>
        <li><NavLink to="/hostels/approved" className="nav_link">Approved</NavLink></li>
        <li><NavLink to="/hostels/pending" className="nav_link">Pending</NavLink></li>
        <li><NavLink to="/hostels/featured" className="nav_link">Featured</NavLink></li>
        <li><NavLink to="/hostels/disapproved" className="nav_link">Disapproved</NavLink> </li>
      </ul>
    </div>
  )
}

export default Hostels
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios'
import dateFormat from 'dateformat';
import { FiEye, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';
import { useAuthContext } from '../../hooks/useAuthContext';
import MainLayout from '../../components/layouts/MainLayout';
import Bookings from './Bookings';
import { NumericFormat } from 'react-number-format';

function AllBookings() {
  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;

  const [bookingsDetails, setBookingsDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState();

  const { user } = useAuthContext();

  const { data: allBookings, isLoading, isError } = useQuery(["AllBookings", page], () => {
    return axios.get(`${baseURL}admin/bookings?page=${page}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });


  const getBookingsDetails = async (userId) => {
    setLoading(true);
    setError(null);

    await axios.get(`${baseURL}admin/bookings/${userId}/show`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((response) => {
      setBookingsDetails(response.data.bookingData);
      setLoading(false);
    }
    ).catch((error) => {
      setLoading(false);
      setError(error.message);
    })
  }

  // get last page value
  const lastPage = allBookings?.last_page;


  // Handle pagination
  const handlePagination = (direction) => {
    if (page < lastPage && direction === "next") {
      setPage(page + 1);

    }

    if (direction === "prev" && page > 1) {
      setPage(page - 1);

    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }

  // Handle page position
  const handleGotoPage = () => {
    if (pageInput && pageInput <= lastPage) {
      setPage(pageInput);
    }
  }



  return (
    <MainLayout>
      <Bookings />
      <div className="overflow-x-auto relative">
        <table className="table table-zebra w-full table-normal">

          <tbody>
            {allBookings?.data?.length > 0 && <tr className='font-semibold uppercase text-gray-500'>
              <td className='text-xs'>Guest Infomation</td>
              <td className='text-xs'>Amount</td>
              <td className='text-xs'>Check In</td>
              <td className='text-xs'>Chekout Out</td>
              <td className='text-xs'>status</td>
              <td></td>
            </tr>}

            {allBookings?.data?.length > 0 ? allBookings?.data?.map((booking, index) => (
              <tr key={booking.id} >
                <td className='flex gap-3 items-center'>
                  <div className="rounded-full h-10 w-10 bg-slate-600 flex items-center justify-center">
                    {booking?.guests?.avatar === "users/default.png" ?
                      (<FiUser className='items-center text-white' size="1.2rem" />) :
                      (<img src={`${resourceURL}${booking?.guests?.avatar}`} alt="" className='rounded-full h-10 w-10 object-cover' />
                      )}

                  </div>
                  <div className="flex flex-col gap-1">
                    <p className='text-gray-600 capitalize'>{booking?.guests?.fName} {booking?.guests?.lName}</p>
                    <span className='text-gray-500 text-sm'>{booking?.guests?.email}</span>
                  </div>
                </td>
                <td className='text-gray-500'><NumericFormat value={booking?.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></td>
                <td className='text-gray-500'>{booking?.checkin_date} {booking?.checkin_time}</td>
                <td className='text-gray-500'>{booking?.checkout_date}</td>
                <td className='text-gray-500'>
                  <span className={`px-2 py-1 text-white text-xs rounded-full ${booking?.status === "active" ? "bg-green-700" : "bg-info"}`}>{booking?.status}</span>
                </td>

                <td>
                  <label htmlFor="my-modal-4"
                    onClick={() => getBookingsDetails(booking.id)}
                    className="cursor-pointer text-green-700 flex gap-2 justify-center items-center"
                  >
                    <FiEye size="1rem" className="" />  <span className='text-sm'>view</span>
                  </label>
                </td>
              </tr>
            )) : (<> {!isLoading ? "No records " : ""}</>)
            }
          </tbody>

        </table>

        {isLoading && <div className='text-center mt-5'>
          <CircularPreloader />
        </div>}

        {/* Pagination */}

        {allBookings && lastPage > 1 && <div className="flex gap-2 mt-6 items-center">
          {page !== 1 && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-40 disabled:cursor-not-allowed'
            disabled={page === 1}
            onClick={() => { handlePagination("prev") }}
          >
            Previous
          </button>}

          {page < lastPage && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            disabled={page === lastPage}
            onClick={() => { handlePagination("next") }}
          >
            Next
          </button>}

          <span className='text-gray-500 text-xs'>{`Page ${page} of ${lastPage}`}</span>

          <input type="text" placeholder="Page" className="input input-bordered input-sm w-16 "
            onChange={(e) => setPageInput(Math.ceil(e.target.value))}
          />

          <button className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            onClick={() => handleGotoPage()}
            disabled={pageInput > lastPage}
          >
            Go
          </button>
        </div>}



        {/* This is the bookings details modal details here */}
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
          <label className="modal-box relative" htmlFor="">

            {!loading && bookingsDetails ? (<>
              <p className='uppercase text-xs text-blue-700 bg-blue-100 px-2 py-1 rounded mb-4 inline-block font-semibold'>Guest details</p>

              <div className="rounded-xl bg-gray-100 min-h-12 h-auto w-full px-4 py-2">

                <div className="flex flex-row gap-5">
                  <div className="h-20 w-20 rounded-full bg-slate-400 mt-4 flex items-center justify-center">
                    {bookingsDetails?.guests?.avatar === "users/default.png" ?
                      (<FiUser className='items-center text-white' size="1.2rem" />) :
                      (<img src={`${resourceURL}${bookingsDetails?.guests?.avatar}`} alt="" className='h-20 w-20 rounded-full object-cover' />
                      )}
                  </div>

                  <div className="py-4 flex flex-col">
                    <span className='capitalize font-medium text-gray-600'>
                      {bookingsDetails?.guests?.fName} {bookingsDetails?.guests?.lName}
                      <span className='bg-success text-white px-1 rounded ml-2 text-xs'>{bookingsDetails?.guests?.gender}</span>
                      <span className={`px-1 ml-2 text-white text-xs rounded ${bookingsDetails?.guests?.status === "Active" ? "bg-success" : "bg-info"}`}>
                        {bookingsDetails?.guests?.status}
                      </span>
                    </span>
                    <span className='text-gray-500 flex items-center gap-2'><FiMail className='' /> {bookingsDetails?.guests?.email}</span>
                    <span className='text-gray-500 flex items-center gap-2'><FiPhone /> {bookingsDetails?.guests?.mobile}</span>


                  </div>
                </div>

              </div>

              <p className='uppercase text-xs text-blue-700 bg-blue-100 px-2 py-1 rounded my-4 inline-block font-semibold'>Booking Details</p>

              <div className='bg-gray-50 px-3 py-2 rounded'>
                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600'>Booking Amount</span>
                  <span className='text-xs text-gray-700'><NumericFormat value={bookingsDetails?.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600 '>Booking Date</span>
                  <span className='text-xs text-gray-700'>{dateFormat(bookingsDetails?.created_at, " mmm dS, yyyy,  h:MM TT")}</span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600'>Reservation ID</span>
                  <span className='text-xs text-gray-700'>{bookingsDetails?.reservation_id}</span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600 '>Transaction ID</span>
                  <span className='text-xs text-gray-700'>{bookingsDetails?.tranz_id}</span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600 '>Check-In Date</span>
                  <span className='text-xs text-gray-700'>{`${bookingsDetails?.checkin_date} - ${bookingsDetails?.checkin_time}`}</span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600 '>Check-Out Date</span>
                  <span className='text-xs text-gray-700'>{bookingsDetails?.checkout_date}</span>
                </div>

              </div>

              {/* Host Data */}
              <p className='uppercase text-xs text-blue-700 bg-blue-100 px-2 py-1 rounded my-4 inline-block font-semibold'>Host Details</p>
              <div className="rounded-xl bg-gray-100 min-h-12 h-auto w-full px-4 py-2">

                <div className="flex flex-row gap-5">
                  <div className="h-20 w-20 rounded-full bg-slate-400 mt-4 flex items-center justify-center">
                    {bookingsDetails?.hostel?.host_data?.avatar === "users/default.png" ?
                      (<FiUser className='items-center text-white' size="1.2rem" />) :
                      (<img src={`${resourceURL}${bookingsDetails?.hostel?.host_data?.avatar}`} alt="" className='h-20 w-20 rounded-full object-cover' />
                      )}
                  </div>

                  <div className="py-4 flex flex-col">
                    <span className='capitalize font-medium text-gray-600'>
                      {bookingsDetails?.hostel?.host_data?.fName} {bookingsDetails?.hostel?.host_data?.lName}
                      <span className='bg-success text-white px-1 rounded ml-2 text-xs'>{bookingsDetails?.hostel?.host_data?.gender}</span>
                      <span className={`px-1 ml-2 text-white text-xs rounded ${bookingsDetails?.hostel?.host_data?.status === "Active" ? "bg-success" : "bg-info"}`}>
                        {bookingsDetails?.guests?.status}
                      </span>
                    </span>
                    <span className='text-gray-500 flex items-center gap-2'><FiMail className='' /> {bookingsDetails?.hostel?.host_data?.email}</span>
                    <span className='text-gray-500 flex items-center gap-2'><FiPhone /> {bookingsDetails?.hostel?.host_data?.mobile}</span>


                  </div>
                </div>

              </div>

              <p className='uppercase text-xs text-blue-700 bg-blue-100 px-2 py-1 rounded my-4 inline-block font-semibold'>Hostel Details</p>

              <div className='bg-gray-50 px-3 py-2 rounded'>
                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600'>Hostel Name</span>
                  <span className='text-xs text-gray-700'>{bookingsDetails?.hostel?.name}</span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600 '>Room Number</span>
                  <span className='text-xs text-gray-700'>{bookingsDetails?.hostel?.room_num}</span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600 '>Hostel Price</span>
                  <span className='text-xs text-gray-700'>N{bookingsDetails?.hostel?.price}</span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600 '>Hostel Location</span>
                  <span className='text-xs text-gray-700'>{bookingsDetails?.hostel?.address}</span>
                </div>

              </div>


            </>) : (<div className='text-center mt-7'> <CircularPreloader /> </div>)}


          </label>
        </label>


      </div>

    </MainLayout>
  )
}

export default AllBookings
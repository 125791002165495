import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios'
import dateFormat from 'dateformat';
import { FiClock, FiEye, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';
import { useAuthContext } from '../../hooks/useAuthContext';
import MainLayout from '../../components/layouts/MainLayout';
import Users from './Users';


function InActiveUsers() {
    const baseURL = process.env.REACT_APP_BASEURL;
    const resourceURL = process.env.REACT_APP_RESOURCE_URL;

    const [userDetails, setUserDetails] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [page, setPage] = useState(1);
    const [pageInput, setPageInput] = useState();

    const { user } = useAuthContext();

    // Get total number of users
    const { data: totalInactiveUsers, isLoading: TisLoading, isError: TisError } = useQuery(["TotalInactiveUsers"], () => {
        return axios.get(`${baseURL}admin/users?status=Pending`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((res) => res.data)
    });

    // Get inactive users with pagination.
    const { data: allUsers, isLoading, isError } = useQuery(["AllUsers", page], () => {
        return axios.get(`${baseURL}admin/users?page=${page}&status=Pending`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((res) => res.data)
    });


    const showDetails = async (userId) => {
        setLoading(true);
        setError(null);

        await axios.get(`${baseURL}admin/users/${userId}/show`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((response) => {
            setUserDetails(response.data);
            setLoading(false);
        }
        ).catch((error) => {
            setLoading(false);
            setError(error.message);
        })
    }

    // get last page value
    const lastPage = allUsers?.last_page;


    // Handle pagination
    const handlePagination = (direction) => {
        if (page < lastPage && direction === "next") {
            setPage(page + 1);

        }

        if (direction === "prev" && page > 1) {
            setPage(page - 1);

        }

        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }

    // Handle page position

    const handleGotoPage = () => {
        if (pageInput && pageInput <= lastPage) {
            setPage(pageInput);
        }
    }



    return (
        <MainLayout>
            <Users />
            <div className="overflow-x-auto relative">

                {totalInactiveUsers?.total > 0 && totalInactiveUsers?.total ? <div className='flex'>
                    <span className='bg-green-50 text-green-900 rounded-md text-sm font-semibold py-2 px-4 mb-5'>
                        {`${totalInactiveUsers?.total} pending user(s) in total.`}
                    </span>
                </div> : <div className='bg-gray-50 text-gray-50 rounded-md text-sm font-semibold py-2 px-4 mb-5 w-48'>.</div>
                }

                <table className="table table-zebra w-full table-normal">

                    <tbody>
                        {allUsers?.data?.length > 0 && <tr className='font-semibold uppercase text-gray-500'>
                            <td className='text-xs'>User Infomation</td>
                            <td className='text-xs'>Gender</td>
                            <td className='text-xs'>Phone Number</td>
                            <td className='text-xs'>Status</td>
                            <td className='text-xs'>Registration Date</td>
                            <td className='text-xs'>Action</td>
                        </tr>}

                        {allUsers?.data?.map((user, index) => (
                            <tr key={user.id} >
                                <td className='flex gap-3 items-center'>
                                    <div className="rounded-full h-10 w-10 bg-slate-600 flex items-center justify-center">
                                        {user.avatar === "default.png" ?
                                            (<FiUser className='items-center text-white' size="1.2rem" />) :
                                            (<img src={`${resourceURL}${user.avatar}`} alt="" className='rounded-full h-10 w-10 object-cover' />
                                            )}

                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className='text-gray-600 capitalize'>{user.fName} {user.lName}</p>
                                        <span className='text-gray-500 text-sm'>{user.email}</span>
                                    </div>
                                </td>
                                <td className='text-gray-500'>{user.gender}</td>
                                <td className='text-gray-500'>{user.mobile}</td>
                                <td className='text-gray-500'>
                                    <span className={`px-2 py-1 text-white text-xs rounded-full ${user.status === "Active" ? "bg-green-700" : "bg-info"}`}>{user.status}</span>
                                </td>
                                <td className='text-gray-500'>{dateFormat(user.created_at, " mmm dS, yyyy,  h:MM TT")}</td>
                                <td>
                                    <label htmlFor="my-modal-4"
                                        onClick={() => showDetails(user.id)}
                                        className="cursor-pointer text-green-700 flex gap-2 justify-center items-center"
                                    >
                                        <FiEye size="1rem" className="" />  <span className='text-sm'>view</span>
                                    </label>
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>

                </table>


                {isLoading && <div className='text-center mt-5'>
                    <CircularPreloader />
                </div>}

                {/* Pagination */}

                {allUsers && lastPage > 1 && <div className="flex gap-2 mt-6 items-center">
                    {page !== 1 && <button
                        className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-40 disabled:cursor-not-allowed'
                        disabled={page === 1}
                        onClick={() => { handlePagination("prev") }}
                    >
                        Previous
                    </button>}

                    {page < lastPage && <button
                        className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
                        disabled={page === lastPage}
                        onClick={() => { handlePagination("next") }}
                    >
                        Next
                    </button>}

                    <span className='text-gray-500 text-xs'>{`Page ${page} of ${lastPage}`}</span>

                    <input type="text" placeholder="Page" className="input input-bordered input-sm w-16 "
                        onChange={(e) => setPageInput(Math.ceil(e.target.value))}
                    />

                    <button className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
                        onClick={() => handleGotoPage()}
                        disabled={pageInput > lastPage}
                    >
                        Go
                    </button>
                </div>}



                {/* This is the user details modal details here */}
                <input type="checkbox" id="my-modal-4" className="modal-toggle" />
                <label htmlFor="my-modal-4" className="modal cursor-pointer">
                    <label className="modal-box relative" htmlFor="">

                        {!loading && userDetails ? (<div className="rounded-xl bg-gray-100 min-h-12 h-auto w-full px-4 py-2">

                            <div className="flex flex-row gap-5">
                                <div className="h-20 w-20 rounded-full bg-slate-400 mt-4 flex items-center justify-center">
                                    {userDetails?.avatar === "default.png" ?
                                        (<FiUser className='items-center text-white' size="1.2rem" />) :
                                        (<img src={`${resourceURL}${userDetails.avatar}`} alt="" className='h-20 w-20 rounded-full object-cover' />
                                        )}
                                </div>

                                <div className="py-4 flex flex-col">
                                    <span className='capitalize font-medium text-gray-600'>
                                        {userDetails?.fName} {userDetails?.lName}
                                        <span className='bg-success text-white px-1 rounded ml-2 text-xs'>{userDetails?.gender}</span>
                                        <span className={`px-1 ml-2 text-white text-xs rounded ${userDetails?.status === "Active" ? "bg-success" : "bg-info"}`}>
                                            {userDetails?.status}
                                        </span>
                                    </span>
                                    <span className='text-gray-500 flex items-center gap-2'><FiMail className='' /> {userDetails?.email}</span>
                                    <span className='text-gray-500 flex items-center gap-2'><FiPhone /> {userDetails?.mobile}</span>
                                    <span className='text-gray-500 flex items-center gap-2 text-xs'><FiClock /> {`Member since : ${dateFormat(userDetails.created_at, " mmm dS, yyyy,  h:MM TT")}`}</span>


                                </div>
                            </div>

                        </div>) : (<div className='text-center mt-7'> <CircularPreloader /> </div>)}


                    </label>
                </label>


            </div>
            
        </MainLayout>
    )
}

export default InActiveUsers
import React, { useState } from 'react'
import MainLayout from '../../components/layouts/MainLayout';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../hooks/useAuthContext';
import { FiEye, FiUser } from 'react-icons/fi';
import dateFormat from 'dateformat';
import { useNavigate } from 'react-router-dom';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';
import { NumericFormat } from 'react-number-format';


function Merchants() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const resourceURL = process.env.REACT_APP_RESOURCE_URL;

    const { user } = useAuthContext();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [page, setPage] = useState(1);
    const [pageInput, setPageInput] = useState();

    const navigate = useNavigate();

    // get all list of merchant
    const { data: getMerchants, isLoading } = useQuery(["GetMerchants", page], () => {
        return axios.get(`${baseURL}admin/merchant?page=${page}`, {
            headers: { 'Authorization': `Bearer ${user}` }
        }).then((res) => res.data);
    });

    // get last page value
    const lastPage = getMerchants?.last_page;

    // Handle pagination
    const handlePagination = (direction) => {
        if (page < lastPage && direction === "next") {
            setPage(page + 1);
        }

        if (direction === "prev" && page > 1) {
            setPage(page - 1);
        }

        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }

    // Handle page position
    const handleGotoPage = () => {
        if (pageInput && pageInput <= lastPage) {
            setPage(pageInput);
        }
    }

    return (
        <MainLayout>

            {isLoading && <div className="flex items-center justify-center w-full h-[83vh] min-h-full">
                <CircularPreloader />
            </div>}

            {!isLoading && <div className='flex items-center justify-between mb-6'>
                <h1 className='text-[18px] font-semibold text-gray-600'>List of merchants
                    <span className='px-2 py-1 ml-2 text-xs text-green-600 bg-green-100'>{getMerchants?.total}</span></h1>
            </div>}

            {!isLoading && <div className="w-full min-h-[83vh] h-full bg-white rounded-md py-2 px-2 flex flex-col justify-between">

                <div className="overflow-x-auto">
                    <table className="table w-full">
                        <thead>
                            <tr className='text-xs text-gray-500'>
                                <th>User</th>
                                <th>Merchant ID</th>
                                <th>Phone Number</th>
                                <th>Balance</th>
                                {/* <th>Users Count</th> */}
                                <th>Registration Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getMerchants?.data.map((merchant) => (
                                <tr key={merchant?.id}>
                                    <td className='flex items-center gap-3'>
                                        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-slate-600">
                                            {merchant.logo === "default.png" ?
                                                (<FiUser className='items-center text-white' size="1.2rem" />) :
                                                (<img src={`${resourceURL}${merchant.logo}`} alt="" className='object-cover w-10 h-10 rounded-full' />
                                                )}

                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <p className='text-gray-600 capitalize'>{merchant.company}</p>
                                            <span className='text-sm text-gray-500'>{merchant.email}</span>
                                        </div>
                                    </td>
                                    <td><span className='text-sm font-medium text-gray-500'>{merchant?.merchant_id}</span></td>
                                    <td><span className='text-sm font-medium text-gray-500'>{merchant?.mobile}</span></td>
                                    <td className=''>
                                        {/* <span className='px-5 py-1 font-medium text-green-700 bg-green-100 rounded'>{merchant?.leads_count}</span> */}
                                        <span className='px-5 py-1 font-medium text-green-700 bg-green-100 rounded'>
                                            <NumericFormat value={merchant?.balance} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                        </span>
                                    </td>
                                    <td><span className='text-sm font-medium text-gray-500'>{dateFormat(merchant?.created_at, " mmm dS, yyyy,  h:MM TT")}</span></td>
                                    <td>
                                        <div className='flex items-center gap-2' onClick={() => navigate(`/merchants/view/${merchant?.id}`)}>
                                            <FiEye size="1rem" className='text-gray-500' />
                                            <span className='text-sm font-medium text-gray-500 cursor-pointer'>View</span>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                {/* Pagination */}

                {getMerchants && lastPage > 1 && <div className="flex items-center gap-2 my-6 ml-4">
                    {page !== 1 && <button
                        className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-40 disabled:cursor-not-allowed'
                        disabled={page === 1}
                        onClick={() => { handlePagination("prev") }}
                    >
                        Previous
                    </button>}

                    {page < lastPage && <button
                        className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
                        disabled={page === lastPage}
                        onClick={() => { handlePagination("next") }}
                    >
                        Next
                    </button>}

                    <span className='text-xs text-gray-500'>{`Page ${page} of ${lastPage}`}</span>

                    <input type="text" placeholder="Page" className="w-16 input input-bordered input-sm "
                        onChange={(e) => setPageInput(Math.ceil(e.target.value))}
                    />

                    <button className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
                        onClick={() => handleGotoPage()}
                        disabled={pageInput > lastPage}
                    >
                        Go
                    </button>
                </div>}

                {/* Empty state for merchant */}
                {getMerchants?.total < 1 && <div className="w-full flex flex-col justify-center items-center gap-5 min-h-[85vh]">
                    <p className='text-2xl font-semibold text-gray-700'>You are yet to have a Merchant</p>
                </div>}

            </div>}

        </MainLayout>
    )
}

export default Merchants

import React from 'react'
import { useLocation } from 'react-router-dom'

function Header() {
  const location = useLocation();

  const pathName = () => {
    const path = location.pathname.replace('/', '');
    if (path === "") {
      return "Dashboard"
    } else if (path === "help") {
      return "Help Center"
    } else if (path.includes("agents")) {
      return "Agents"
    } else if (path.includes("merchants/view")) {
      return "Merchant Details"
    } else {
      return path;
    }
  }
  return (
    <div className='px-5 py-3 flex items-center bg-white shadow-sm h-16 z-20  w-full fixed top-0'>
      <h4 className='text-blue-800 capitalize font-bold'>{pathName()}</h4>
    </div>
  )
}

export default Header
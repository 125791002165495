import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import AuthGuard from './components/shared/AuthGuard';
import ActiveBookings from './pages/bookings/ActiveBookings';
import AllBookings from './pages/bookings/AllBookings';
import ClosedBookings from './pages/bookings/ClosedBookings';
import PendingBookings from './pages/bookings/PendingBookings';
import Dashboard from './pages/dashboard/Dashboard';
import Allrequest from './pages/help-center/Allrequest';
import PendingRequest from './pages/help-center/PendingRequest';
import ResolvedRequest from './pages/help-center/ResolvedRequest';
import AllHostels from './pages/hostels/AllHostels';
import ApprovedHostels from './pages/hostels/ApprovedHostels';
import DisApprovedHostels from './pages/hostels/DisApprovedHostels';
import PendingHostels from './pages/hostels/PendingHostels';
import Login from './pages/login/Login';
import NotFound from './pages/notfound/NotFound';
import AllTransactions from './pages/transactions/AllTransactions';
import Payable from './pages/transactions/Payable';
import Payouts from './pages/transactions/Payouts';
import ActiveUsers from './pages/users/ActiveUsers';
import AllUsers from './pages/users/AllUsers';
// import Merchants from './pages/users/Merchants';
import InActiveUsers from './pages/users/InActiveUsers';
import FeaturedHostels from './pages/hostels/FeaturedHostels';
import Agents from './pages/agents/Agents';
import AgentView from './pages/agents/AgentView';
import Merchants from './pages/merchants/Merchants';
import MerchantView from './pages/merchants/MerchantView';

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} exact></Route>

        <Route element={<AuthGuard />}>

          <Route path='/dashboard' element={<Dashboard />} />

          <Route path='/users'>
            <Route index element={<AllUsers />} />
            <Route path='active' element={<ActiveUsers />} />
            <Route path='inactive' element={<InActiveUsers />} />
            <Route path='merchants' element={<Merchants />} />
          </Route>


          <Route path='/hostels'>
            <Route index element={<AllHostels />} />
            <Route path='all' element={<AllHostels />} />
            <Route path='approved' element={<ApprovedHostels />} />
            <Route path='pending' element={<PendingHostels />} />
            <Route path='featured' element={<FeaturedHostels />} />
            <Route path='disapproved' element={<DisApprovedHostels />} />
          </Route>

          <Route path='/bookings'>
            <Route index element={<AllBookings />} />
            <Route path='active' element={<ActiveBookings />} />
            <Route path='pending' element={<PendingBookings />} />
            <Route path='closed' element={<ClosedBookings />} />
          </Route>

          <Route path='/transactions'>
            <Route index element={<AllTransactions />} />
            <Route path='payable' element={<Payable />} />
            <Route path='payout' element={<Payouts />} />
          </Route>

          <Route path='/help'>
            <Route index element={<Allrequest />} />
            <Route path='pending' element={<PendingRequest />} />
            <Route path='resolved' element={<ResolvedRequest />} />
          </Route>

          <Route path='/agents'>
            <Route index element={<Agents />} />
            <Route path='view/:id' element={<AgentView />} />
          </Route>

          <Route path='/merchants'>
            <Route index element={<Merchants />} />
            <Route path='view/:id' element={<MerchantView />} />
          </Route>

          <Route path='*' element={<NotFound />} />

        </Route> 

      </Routes>
    </Router>
  );
}

export default App;

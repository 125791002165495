import React from 'react'
import Header from '../header/Header'

function Main({ children }) {
    return (
        <div className="flex-1 bg-gray-100 min-h-screen pt-16">
            <Header />
            <div className='px-6 py-6'>
                {children}
            </div>
        </div>
    )
}

export default Main
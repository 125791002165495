import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Logo from './../../../assets/images/img/logo_gray.png';
import { FiGrid, FiUsers, FiHome, FiCreditCard, FiActivity, FiHelpCircle, FiLogOut, FiUserCheck } from "react-icons/fi";
import { useLogOut } from '../../../hooks/useLogOut';

function SideBar() {

    const { logOut } = useLogOut();
    const navigate = useNavigate();

    const handleLogOut = () => {
        logOut();
        navigate('/');
    }

    return (
        <div className='w-64 bg-white border-r border-gray-200 shadow-sm min-h-screen py-5 px-7'>

            <div>
                <img src={Logo} alt="Hostel.ng Logo" className='h-8' />
            </div>
            <ul className='flex flex-col gap-3 space-y-3 mt-14'>
                <li>
                    <NavLink to="/dashboard" className='flex flex-row gap-4 items-center nav_link'><FiGrid /> Dashboard</NavLink>
                </li>
                <li>
                    <NavLink to="/users" className='flex flex-row gap-4 items-center nav_link'><FiUsers /> Users</NavLink>
                </li>
                <li>
                    <NavLink to="/hostels" className='flex flex-row gap-4 items-center nav_link'><FiHome /> Hostels</NavLink>
                </li>
                <li>
                    <NavLink to="/bookings" className='flex flex-row gap-4 items-center nav_link'><FiCreditCard /> Bookings</NavLink>
                </li>
                <li>
                    <NavLink to="/transactions" className='flex flex-row gap-4 items-center nav_link'><FiActivity /> Transactions</NavLink>
                </li>
                <li>
                    <NavLink to="/agents" className='flex flex-row gap-4 items-center nav_link'><FiUsers /> Agents</NavLink>
                </li>
                <li>
                    <NavLink to="/merchants" className='flex flex-row gap-4 items-center nav_link'><FiUserCheck /> Merchants</NavLink>
                </li>
                <li>
                    <NavLink to="/help" className='flex flex-row gap-4 items-center nav_link'><FiHelpCircle /> Help Center</NavLink>
                </li>
                <li>
                    <span className='flex flex-row gap-4 items-center 
                        nav_link !text-red-600 cursor-pointer'
                        onClick={() => handleLogOut()}
                    >
                        <FiLogOut /> Log Out
                    </span>
                </li>
            </ul>


        </div>
    )
}

export default SideBar
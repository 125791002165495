import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react'
import { FiEye, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import MainLayout from '../../components/layouts/MainLayout'
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';
import { useAuthContext } from '../../hooks/useAuthContext';
import Transactions from './Transactions'
import dateFormat from 'dateformat';
import { NumericFormat } from 'react-number-format';
import EmptyList from '../../assets/images/svg/empty-list.svg';

function AllTransactions() {

  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;

  const [transactionsDetails, setTransactionsDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState();

  const { user } = useAuthContext();

  // Get total number of all transactions
  const { data: totalTransactions, isLoading: TisLoading, isError: TisError } = useQuery(["TotalTransactions"], () => {
    return axios.get(`${baseURL}admin/withdrawals`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  // Get all Transactions
  const { data: allTransactions, isLoading, isError } = useQuery(["AllTransactions", page], () => {
    return axios.get(`${baseURL}admin/withdrawals?page=${page}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });


  const getTransactionsDetails = async (userId) => {
    setLoading(true);
    setError(null);

    await axios.get(`${baseURL}admin/withdrawals/${userId}/show`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((response) => {
      setTransactionsDetails(response.data.withdrawal);
      setLoading(false);
    }
    ).catch((error) => {
      setLoading(false);
      setError(error.message);
    })
  }

  // get last page value
  const lastPage = allTransactions?.last_page;


  // Handle pagination
  const handlePagination = (direction) => {
    if (page < lastPage && direction === "next") {
      setPage(page + 1);

    }

    if (direction === "prev" && page > 1) {
      setPage(page - 1);

    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }

  // Handle page position
  const handleGotoPage = () => {
    if (pageInput && pageInput <= lastPage) {
      setPage(pageInput);
    }
  }

  return (
    <MainLayout>
      <Transactions />

      {totalTransactions?.total > 0 && totalTransactions?.total ? <div className='flex'>
        <span className='bg-green-50 text-green-900 rounded-md text-sm font-semibold py-2 px-4 mb-5'>
          {`${totalTransactions?.total}  transactions in total.`}
        </span>
      </div> : <div className='bg-gray-50 text-gray-50 rounded-md text-sm font-semibold py-2 px-4 mb-5 w-48'>.</div>
      }

      <div className="overflow-x-auto relative">
        <table className="table table-zebra w-full table-normal">

          <tbody>
            {allTransactions?.data?.length > 0 && <tr className='font-semibold uppercase text-gray-500'>
              <td className='text-xs'>Guest Infomation</td>
              <td className='text-xs'>Amount Requested</td>
              <td className='text-xs'>Current Balance</td>
              <td className='text-xs'>Role</td>
              <td className='text-xs'>Request Date</td>
              <td className='text-xs'>status</td>
              <td></td>
            </tr>}

            {allTransactions?.data?.length > 0 ? allTransactions?.data?.map((transaction, index) => (
              <tr key={transaction.id} >
                <td className='flex gap-3 items-center'>

                  {transaction?.user === null ? (<>
                    <div className="rounded-full h-10 w-10 bg-slate-600 flex items-center justify-center">
                      {transaction?.merchant?.logo === "default.png" ?
                        (<FiUser className='items-center text-white' size="1.2rem" />) :
                        (<img src={`${resourceURL}${transaction?.merchant?.logo}`} alt="" className='rounded-full h-10 w-10 object-cover' />
                        )}

                    </div>
                    <div className="flex flex-col gap-1">
                      <p className='text-gray-600 capitalize'>{transaction?.merchant?.company}</p>
                      <span className='text-gray-500 text-sm'>{transaction?.merchant?.email}</span>
                    </div>
                  </>) : (<>
                    <div className="rounded-full h-10 w-10 bg-slate-600 flex items-center justify-center">
                      {transaction?.user?.avatar === "default.png" ?
                        (<FiUser className='items-center text-white' size="1.2rem" />) :
                        (<img src={`${resourceURL}${transaction?.user?.avatar}`} alt="" className='rounded-full h-10 w-10 object-cover' />
                        )}

                    </div>
                    <div className="flex flex-col gap-1">
                      <p className='text-gray-600 capitalize'>{transaction?.user?.fName} {transaction?.user?.lName}</p>
                      <span className='text-gray-500 text-sm'>{transaction?.user?.email}</span>
                    </div>
                  </>)}
                </td>

                <td className='text-gray-500 font-medium'>
                  <NumericFormat value={transaction?.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                </td>

                <td className='text-gray-700 font-semibold'>
                  <div className='flex gap-1'>
                    {transaction?.user === null ?
                      <NumericFormat value={transaction?.merchant?.balance} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> :
                      <NumericFormat value={transaction?.user?.balance} displayType={'text'} thousandSeparator={true} prefix={'₦'} />}
                  </div>
                </td>

                <td className=''>
                  {transaction?.user === null ? <span className='text-xs rounded-full px-2 py-[4px] bg-orange-200 text-orange-800'>Merchant</span> :
                    <span className='text-xs rounded-full px-2 py-[4px] bg-blue-100 text-blue-800'>Host/Referral</span>}
                </td>

                <td className='text-gray-500'>{dateFormat(transaction?.created_at, " mmm dS, yyyy,  h:MM TT")}</td>
                <td className='text-gray-500'>
                  <span className={`px-2 py-1 text-xs rounded-full ${transaction?.status === "Approved" ? "bg-green-200 text-green-700" : "bg-red-200 text-red-700"}`}>{transaction?.status}</span>
                </td>

                <td>
                  <label htmlFor="my-modal-4"
                    onClick={() => getTransactionsDetails(transaction.id)}
                    className="cursor-pointer text-green-700 flex gap-2 justify-center items-center"
                  >
                    <FiEye size="1rem" className="" />  <span className='text-sm'>view</span>
                  </label>
                </td>
              </tr>
            )) : (<> {!isLoading ?
              (<div className='flex justify-center items-center flex-col pt-32'>
                <img src={EmptyList} alt='' />
                <span className='text-gray-400 mt-5'>You are yet to make a transaction.</span>
              </div>) : ""}</>)
            }
          </tbody>

        </table>

        {isLoading && <div className='text-center mt-5'>
          <CircularPreloader />
        </div>}

        {/* Pagination */}

        {allTransactions && lastPage > 1 && <div className="flex gap-2 mt-6 items-center">
          {page !== 1 && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-40 disabled:cursor-not-allowed'
            disabled={page === 1}
            onClick={() => { handlePagination("prev") }}
          >
            Previous
          </button>}

          {page < lastPage && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            disabled={page === lastPage}
            onClick={() => { handlePagination("next") }}
          >
            Next
          </button>}

          <span className='text-gray-500 text-xs'>{`Page ${page} of ${lastPage}`}</span>

          <input type="text" placeholder="Page" className="input input-bordered input-sm w-16 "
            onChange={(e) => setPageInput(Math.ceil(e.target.value))}
          />

          <button className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            onClick={() => handleGotoPage()}
            disabled={pageInput > lastPage}
          >
            Go
          </button>
        </div>}



        {/* This is the bookings details modal details here */}
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
          <label className="modal-box relative" htmlFor="">

            {!loading && transactionsDetails ? (<>
              <p className='uppercase text-xs text-blue-700 bg-blue-100 px-2 py-1 rounded mb-4 inline-block font-semibold'>User Details</p>

              <div className="rounded-xl bg-gray-100 min-h-12 h-auto w-full px-4 py-2">
                {/* User or merchant information section */}
                <div className="flex flex-row gap-5">
                  {transactionsDetails?.user === null ? <>
                    <div className="h-20 w-20 rounded-full bg-slate-400 mt-4 flex items-center justify-center">
                      {transactionsDetails?.merchant?.logo === "users/default.png" ?
                        (<FiUser className='items-center text-white' size="1.2rem" />) :
                        (<img src={`${resourceURL}${transactionsDetails?.merchant?.logo}`} alt="" className='h-20 w-20 rounded-full object-cover' />
                        )}
                    </div>

                    <div className="py-4 flex flex-col">
                      <span className='capitalize font-medium text-gray-600'>
                        {transactionsDetails?.merchant?.company}
                      </span>
                      <span className='text-gray-500 flex items-center gap-2'><FiMail className='' /> {transactionsDetails?.merchant?.email}</span>
                      <span className='text-gray-500 flex items-center gap-2'><FiPhone /> {transactionsDetails?.merchant?.mobile}</span>
                      <span className='px-1 text-xs text-gray-500'>Merchant ID: {transactionsDetails?.merchant?.merchant_id}</span>
                    </div>

                  </> :
                    <>
                      <div className="h-20 w-20 rounded-full bg-slate-400 mt-4 flex items-center justify-center">
                        {transactionsDetails?.user?.avatar === "users/default.png" ?
                          (<FiUser className='items-center text-white' size="1.2rem" />) :
                          (<img src={`${resourceURL}${transactionsDetails?.user?.avatar}`} alt="" className='h-20 w-20 rounded-full object-cover' />
                          )}
                      </div>

                      <div className="py-4 flex flex-col">
                        <span className='capitalize font-medium text-gray-600'>
                          {transactionsDetails?.user?.fName} {transactionsDetails?.user?.lName}
                          <span className='bg-success text-white px-1 rounded ml-2 text-xs'>{transactionsDetails?.user?.gender}</span>
                          <span className={`px-1 ml-2 text-white text-xs rounded ${transactionsDetails?.user?.status === "Active" ? "bg-green-200 text-green-700" : "bg-info"}`}>
                            {transactionsDetails?.user?.status}
                          </span>
                        </span>
                        <span className='text-gray-500 flex items-center gap-2'><FiMail className='' /> {transactionsDetails?.user?.email}</span>
                        <span className='text-gray-500 flex items-center gap-2'><FiPhone /> {transactionsDetails?.user?.mobile}</span>
                      </div>
                    </>}

                </div>

              </div>

              <p className='uppercase text-xs text-blue-700 bg-blue-100 px-2 py-1 rounded my-4 inline-block font-semibold'>Withdrawal Request</p>

              <div className='bg-gray-50 px-3 py-2 rounded'>
                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600'>Amount</span>
                  <span className='text-xs text-gray-700'><NumericFormat value={transactionsDetails?.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600 '>Booking Date</span>
                  <span className='text-xs text-gray-700'>{dateFormat(transactionsDetails?.created_at, " mmm dS, yyyy,  h:MM TT")}</span>
                </div>

                <div className='flex justify-between gap-3 py-2 '>
                  <span className='text-xs text-gray-600'>Withdrawal Status</span>
                  <span className={`px-2 py-1 text-xs rounded-full ${transactionsDetails?.status === "Approved" ? "bg-green-200 text-green-700" : "bg-red-200 text-red-700"}`}>{transactionsDetails?.status}</span>
                </div>

              </div>

            </>) : (<div className='text-center mt-7'> <CircularPreloader /> </div>)}


          </label>
        </label>


      </div>

    </MainLayout>
  )
}

export default AllTransactions
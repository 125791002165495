import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, PieChart, Pie, Cell, } from 'recharts'
import MainLayout from '../../components/layouts/MainLayout'
import { useAuthContext } from '../../hooks/useAuthContext';
import { NumericFormat } from 'react-number-format';
import SelectField from '../../components/molecules/forms/select/SelectField';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Dashboard Icons
import BookingsIcon from "../../assets/images/svg/bookings-count-icon.svg";
import GrossManchandiseIcon from "../../assets/images/svg/gross-merchandise.svg";
import UploadedRoomsIcon from "../../assets/images/svg/uploaded-rooms-icon.svg";
import TotalRevenueIcon from "../../assets/images/svg/total-revenue-icon.svg";
import RegisteredUsersIcon from "../../assets/images/svg/registered-users-icon.svg";
import RequestCountIcon from "../../assets/images/svg/request-count-icon.svg";
import ContentLoader from 'react-content-loader';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';

function Dashboard() {

  const baseURL = process.env.REACT_APP_BASEURL;

  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [totalRevenueValue, setTotalRevenueValue] = useState("");
  const [hostelRevenueValue, setHostelRevenueValue] = useState("");
  const [bookingsValue, setBookingsValue] = useState("");
  const [bookingsGraphValue, setBookingsGraphValue] = useState("year");
  const [bookingsStatusValue, setBookingsStatusValue] = useState("");
  const [signUpGraphValue, setSignUpGraphValue] = useState("year");
  const [genderValue, setGenderValue] = useState("");

  const [hostelsValue, setHostelsValue] = useState("");
  const [usersValue, setUsersValue] = useState("");
  const [requestValue, setRequestValue] = useState("");

  const [hostPayoutValue, setHostPayoutValue] = useState("");
  const [guestPayoutValue, setGuestPayoutValue] = useState("");
  const [merchantPayoutValue, setMerchantPayoutValue] = useState("");

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  const filterOptions = [
    { value: "all", label: "All Time" },
    { value: "today", label: "Today" },
    { value: "week", label: "This week" },
    { value: "month", label: "This month" },
    { value: "year", label: "This year" },
  ]

  const graphFilterOptions = [
    { value: "year", label: "This year" },
    { value: "week", label: "This week" },
  ]

  const earningsData = [
    { days: 'Mon', earnings: 3540 },
    { days: 'Tue', earnings: 67010 },
    { days: 'Wed', earnings: 50020 },
    { days: 'Thur', earnings: 55800 },
    { days: 'Fri', earnings: 72890 },
    { days: 'Sat', earnings: 48950 },
    { days: 'Sun', earnings: 15203 },
  ]

  const earningsYearlyData = [
    { months: 'JAN', earnings: 98379 },
    { months: 'FEB', earnings: 74887 },
    { months: 'MAR', earnings: 46728 },
    { months: 'APR', earnings: 46288 },
    { months: 'MAY', earnings: 70000 },
    { months: 'JUN', earnings: 99821 },
    { months: 'JUL', earnings: 3390 },
    { months: 'AUG', earnings: 1395 },
    { months: 'SEP', earnings: 4893 },
    { months: 'OCT', earnings: 6722 },
    { months: 'NOV', earnings: 78632 },
    { months: 'DEC', earnings: 100599 },
  ]

  const usersBookingStatus = [
    { name: 'Active', value: 40 },
    { name: 'Pending', value: 60 },
    { name: 'Closed', value: 70 },
  ];


  // Total revenue
  const { data: getTotalRevenue, isLoading: TRLoading } = useQuery(["TotalRevenue", totalRevenueValue], () => {
    return axios.get(`${baseURL}admin/dashboard/revenue?filter=${totalRevenueValue ? totalRevenueValue : "all"}`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  // Total hostel.ng revenue
  const { data: getHostelRevenue, isLoading: THLoading } = useQuery(["HostelRevenue", hostelRevenueValue], () => {
    return axios.get(`${baseURL}admin/dashboard/income?filter=${hostelRevenueValue ? hostelRevenueValue : "all"}`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  // Total Bookings Count
  const { data: getBookingsCount, isLoading: BCLoading } = useQuery(["BookingsCount", bookingsValue], () => {
    return axios.get(`${baseURL}admin/dashboard/bookings?filter=${bookingsValue ? bookingsValue : "all"}`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  // Total Hostels Count
  const { data: getHostelsCount, isLoading: HCLoading } = useQuery(["HostelsCount", hostelsValue], () => {
    return axios.get(`${baseURL}admin/dashboard/hostel?filter=${hostelsValue ? hostelsValue : "all"}`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  // Total Users Count
  const { data: getUsersCount, isLoading: UCLoading } = useQuery(["UsersCount", usersValue], () => {
    return axios.get(`${baseURL}admin/dashboard/user?filter=${usersValue ? usersValue : "all"}`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  // Get Total request count.
  const { data: getRequestCount, isLoading: RCLoading } = useQuery(["RequestCount", requestValue], () => {
    return axios.get(`${baseURL}admin/dashboard/help?filter=${requestValue ? requestValue : "all"}`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data);
  });

  // Get Total Host Payout.
  const { data: getHostPayout, isLoading: HPLoading } = useQuery(["HostPayout", hostPayoutValue], () => {
    return axios.get(`${baseURL}admin/dashboard/payout/host?filter=${hostPayoutValue ? hostPayoutValue : "all"}`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data);
  });

  // Get Total Guest Payout.
  const { data: getGuestPayout, isLoading: GPLoading } = useQuery(["GuestPayout", guestPayoutValue], () => {
    return axios.get(`${baseURL}admin/dashboard/payout/referral?filter=${guestPayoutValue ? guestPayoutValue : "all"}`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data);
  });

  // Get Total Merchant Payout.
  const { data: getMerchantPayout, isLoading: MPLoading } = useQuery(["MerchantPayout", merchantPayoutValue], () => {
    return axios.get(`${baseURL}admin/dashboard/payout/merchant?filter=${merchantPayoutValue ? merchantPayoutValue : "all"}`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data);
  });

  // Get User Graph details
  const { data: signupData, isLoading: SGLoading } = useQuery(["SignUpGraph", signUpGraphValue], () => {
    return axios.get(`${baseURL}admin/dashboard/graph/user?type=${signUpGraphValue ? signUpGraphValue : "year"}`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data);
  });

  // Get gender piechart details
  const { data: usersGender, isLoading: UGLoading } = useQuery(["UsersGender", genderValue], () => {
    return axios.get(`${baseURL}admin/dashboard/chart/gender?type=${genderValue ? genderValue : "all"}`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data);
  });

  // Check if gender count for male and female is equal to zero to display empty state 
  const genderCounter = [];
  usersGender?.forEach(item => {
    if (item.count === 0) {
      genderCounter.push(item.count);
    }
  });

  // Get Bookings Graph data
  const { data: getBookingsGraph, isLoading: BGLoading } = useQuery(["GetBookingsGraph", bookingsGraphValue], () => {
    return axios.get(`${baseURL}admin/dashboard/graph/earnings?type=${bookingsGraphValue ? bookingsGraphValue : "year"}`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data);
  });

  // Get Bookings status data
  const { data: getBookingsStatus, isLoading: BSLoading } = useQuery(["GetBookingsStatus", bookingsStatusValue], () => {
    return axios.get(`${baseURL}admin/dashboard/chart/earnings?type=${bookingsStatusValue ? bookingsStatusValue : "all"}`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data);
  });

  // Check if gender count for male and female is equal to zero to display empty state 
  const bookingsStatusCounter = [];
  getBookingsStatus?.forEach(item => {
    if (item.count === 0) {
      bookingsStatusCounter.push(item.count);
    }
  });

  // Pie chart colors
  const COLORS = ['#064AD7', '#38B188'];
  const BCOLORS = ['#064AD7', '#fecaca', '#38B188'];

  return (
    <MainLayout>
      <div className="">
        <div className="grid grid-cols-3 gap-6 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 gap-x-6 gap-y-6">

          {/* Total Revenue */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">

            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={GrossManchandiseIcon} alt="" />
                <span className='font-semibold text-gray-500'>Gross Merchandise Volume</span>
              </div>
              <SelectField options={filterOptions} setValue={setTotalRevenueValue} />
            </div>

            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {TRLoading ? (<ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>) : (<NumericFormat value={getTotalRevenue?.totalRevenue} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'₦'} />
              )}

            </div>
          </div>

          {/* Hostel.ng Revenue */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">
            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={TotalRevenueIcon} alt="" />
                <span className='font-semibold text-gray-500'>Total Revenue</span>
              </div>
              <SelectField options={filterOptions} setValue={setHostelRevenueValue} />
            </div>
            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {!THLoading && <NumericFormat value={getHostelRevenue?.incomeMade} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'₦'} />}
              {THLoading && <ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>}
            </div>
          </div>

          {/* Numbers of Bookings */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">
            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={BookingsIcon} alt="" />
                <span className='font-semibold text-gray-500'>Number of Bookings</span>
              </div>
              <SelectField options={filterOptions} setValue={setBookingsValue} />
            </div>

            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {!BCLoading && <NumericFormat value={getBookingsCount?.bookingsCount} displayType={'text'} thousandSeparator={true} />}
              {BCLoading && <ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>}
            </div>
          </div>

          {/* Numbers of Hostels */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">
            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={UploadedRoomsIcon} alt="" />
                <span className='font-semibold text-gray-500'>Uploaded Rooms</span>
              </div>
              <SelectField options={filterOptions} setValue={setHostelsValue} />
            </div>

            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {!HCLoading && <NumericFormat value={getHostelsCount?.hostelCount} displayType={'text'} thousandSeparator={true} />}
              {HCLoading && <ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>}
            </div>
          </div>

          {/* Numbers of Users */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">
            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={RegisteredUsersIcon} alt="" />
                <span className='font-semibold text-gray-500'>Registered Users</span>
              </div>
              <SelectField options={filterOptions} setValue={setUsersValue} />
            </div>

            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {!UCLoading && <NumericFormat value={getUsersCount?.userCount} displayType={'text'} thousandSeparator={true} />}
              {UCLoading && <ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>}
            </div>
          </div>

          {/* Numbers of Request */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">
            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={RequestCountIcon} alt="" />
                <span className='font-semibold text-gray-500'>Number of Requests</span>
              </div>
              <SelectField options={filterOptions} setValue={setRequestValue} />
            </div>

            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {!RCLoading && <NumericFormat value={getRequestCount?.helpCount} displayType={'text'} thousandSeparator={true} />}
              {RCLoading && <ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>}
            </div>
          </div>

          {/* Total host payout  */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">
            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={GrossManchandiseIcon} alt="" />
                <span className='font-semibold text-gray-500'>Total Host Payout</span>
              </div>
              <SelectField options={filterOptions} setValue={setHostPayoutValue} />
            </div>

            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {!HPLoading && <NumericFormat value={getHostPayout?.withdrawalSum} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'₦'} />}
              {HPLoading && <ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>}
            </div>
          </div>


          {/* Total Guest Payout */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">
            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={TotalRevenueIcon} alt="" />
                <span className='font-semibold text-gray-500'>Total Guest Payout</span>
              </div>
              <SelectField options={filterOptions} setValue={setGuestPayoutValue} />
            </div>

            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {!GPLoading && <NumericFormat value={getGuestPayout?.withdrawalSum} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'₦'} />}
              {GPLoading && <ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>}
            </div>
          </div>

          {/* Total Merchant Payout */}
          <div className="h-40 duration-300 ease-in bg-white rounded-md drop-shadow-sm p-7 hover:bg-blue-50">
            <div className='flex items-center justify-between gap-2'>
              <div className="flex items-center gap-3">
                <img src={TotalRevenueIcon} alt="" />
                <span className='font-semibold text-gray-500'>Total Merchant Payout</span>
              </div>
              <SelectField options={filterOptions} setValue={setMerchantPayoutValue} />
            </div>

            <div className="pt-4 mt-4 text-3xl font-bold text-slate-600">
              {!RCLoading && <NumericFormat value={getMerchantPayout?.withdrawalSum} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'₦'} />}
              {RCLoading && <ContentLoader
                speed={2}
                width={150}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}

              >
                <rect x="0" y="0" rx="6" ry="6" width="150" height="35" />
              </ContentLoader>}
            </div>
          </div>

        </div>


        <div className="flex w-full gap-6 mt-14">
          {/* User signups graph */}
          <div className="w-4/5 p-6 bg-white rounded-md drop-shadow-sm">
            {/* User signup Title and filter */}
            <div className="flex justify-between mb-10">
              <h4 className='text-lg font-bold text-gray-600'>Users Signup {`for ${signUpGraphValue && signUpGraphValue === "year" ? "this year" : "this week"}`} </h4>
              <SelectField options={graphFilterOptions} setValue={setSignUpGraphValue} />
            </div>

            {/* User signups graph */}
            <div className="flex w-full h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={300}
                  margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
                  data={signupData ? signupData : []}
                >
                  <defs>
                    <linearGradient id="signupColors" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#83A4EB" stopOpacity={0.8} />
                      <stop offset="100%" stopColor="#CDDBF7" stopOpacity={0} />
                    </linearGradient>
                  </defs>

                  <XAxis dataKey={signUpGraphValue === "year" ? "months" : "day"} />
                  <YAxis />
                  <Tooltip />

                  <Area type="monotone" dataKey="signups" stroke="#074AD7" fillOpacity={1} fill="url(#signupColors)" />

                </AreaChart>
              </ResponsiveContainer>
            </div>

          </div>

          <div className="w-2/5 p-6 bg-white rounded-md drop-shadow-sm">

            {/* Loading state for user gender piechart */}
            {UGLoading && <div className="flex items-center justify-center w-full h-full">
              <CircularPreloader />
            </div>}

            {/* Gender Title and filter */}
            <div className={`flex justify-between mb-10 ${UGLoading ? "hidden" : ""}`}>
              <h4 className='text-lg font-bold text-gray-600'>Gender</h4>
              <SelectField options={filterOptions} setValue={setGenderValue} />
            </div>


            {/* Render this after user gender data has been completely fetched */}
            {usersGender &&
              <div className=''>

                {/* Gender pie chart */}
                <div className="flex items-center justify-center w-full h-80">
                  {genderCounter?.length === 2 ? (<div className="flex items-center justify-center bg-gray-500 rounded-full h-52 w-52">
                    <div className="flex items-center justify-center bg-white rounded-full h-36 w-36">
                      <span className='text-gray-500'>No Data</span>
                    </div>
                  </div>) : (<ResponsiveContainer width="100%" height="100%">
                    <PieChart >
                      <Pie
                        dataKey="count"
                        data={usersGender ? usersGender : []}
                        cx="50%" cy="50%"
                        innerRadius={60}
                        outerRadius={90}
                        fill="#82ca9d"
                        label>
                        {usersGender?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>)}
                </div>

                {/* Legends */}
                <div className="flex flex-col gap-4">
                  {usersGender?.map((item) => (
                    <div key={item.gender} className="flex flex-row items-center justify-between">

                      <div className="flex items-center gap-2">
                        <div className={`rounded-full w-2 h-2 
                                      ${item.gender === "Male" ? "bg-[#064AD7]" : ""}
                                      ${item.gender === "Female" ? "bg-[#38B188]" : ""}
                                    `}>
                        </div>
                        <span className='font-semibold text-gray-600'>{item.gender}</span>
                      </div>

                      <div className="font-bold"> <NumericFormat value={item.count} displayType={'text'} thousandSeparator={true} /></div>
                    </div>
                  ))}
                </div>
              </div>
            }

          </div>

        </div>

        {/* Bookings graphical representation */}
        <div className="flex w-full gap-6 mt-14">
          <div className="w-4/5 p-6 bg-white rounded-md drop-shadow-sm">
            {/* Bookings Title and filter */}
            <div className="flex justify-between mb-10">
              <h4 className='text-lg font-bold text-gray-600'>
                Bookings Stats {`for ${bookingsGraphValue && bookingsGraphValue === "year" ? "this year" : "this week"}`}
              </h4>
              <SelectField options={graphFilterOptions} setValue={setBookingsGraphValue} />
            </div>

            {/* Bookings graph */}
            <div className="flex w-full h-[430px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={300}
                  margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
                  data={getBookingsGraph ? getBookingsGraph : []}
                >
                  <defs>
                    <linearGradient id="earningsColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#9BD8C3" stopOpacity={0.8} />
                      <stop offset="100%" stopColor="#D7EFE7" stopOpacity={0} />
                    </linearGradient>
                  </defs>

                  <XAxis dataKey={bookingsGraphValue === "year" ? "month" : "day"} />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="amount" stroke="#9BD8C3" fillOpacity={1} fill="url(#earningsColor)" />
                </AreaChart>
              </ResponsiveContainer>
            </div>

          </div>

          <div className="w-2/5 p-6 bg-white rounded-md drop-shadow-sm">

            {/* Loading state for user gender piechart */}
            {BSLoading && <div className="flex items-center justify-center w-full h-full">
              <CircularPreloader />
            </div>}

            {/* Bookings Title and filter */}
            <div className={`flex justify-between mb-10 ${BSLoading ? "hidden" : ""}`}>
              <h4 className='text-lg font-bold text-gray-600'>Bookings status</h4>
              <SelectField options={filterOptions} setValue={setBookingsStatusValue} />
            </div>

            {!BSLoading && <>

              {/* Bookings status pie chart */}
              <div className="flex items-center justify-center w-full h-80">

                {bookingsStatusCounter?.length === 3 ? (
                  <div className="flex items-center justify-center bg-gray-500 rounded-full h-52 w-52">
                    <div className="flex items-center justify-center bg-white rounded-full h-36 w-36">
                      <span className='text-gray-500'>No Data</span>
                    </div>
                  </div>
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart >
                      <Pie
                        dataKey="count"
                        data={getBookingsStatus ? getBookingsStatus : []}
                        cx="50%" cy="50%"
                        innerRadius={60}
                        outerRadius={90}
                        fill="#82ca9d"
                        label>
                        {getBookingsStatus?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={BCOLORS[index % BCOLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </div>

              {/* Legends */}
              <div className="flex flex-col gap-4">
                {getBookingsStatus?.map((item) => (
                  <div key={item.status} className="flex flex-row items-center justify-between">

                    <div className="flex items-center gap-2">
                      <div className={`rounded-full w-2 h-2 
                      ${item.status === "active" ? "bg-[#064AD7]" : ""}
                      ${item.status === "pending" ? "bg-red-200" : ""}
                      ${item.status === "closed" ? "bg-[#38B188]" : ""}
                      `}
                      ></div>
                      <span className='font-semibold text-gray-600 capitalize'>{item.status} Bookings</span>
                    </div>

                    <div className="font-bold"> <NumericFormat value={item.count} displayType={'text'} thousandSeparator={true} /></div>
                  </div>
                ))}

              </div>
            </>}

          </div>

        </div>

      </div>
    </MainLayout >
  )
}

export default Dashboard
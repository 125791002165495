import React, { useState } from 'react'

function SelectField({options, setValue}) {

    const handleOnChange = (e) => {
        e.preventDefault();
        setValue(e.target.value);
    }

    return (
        <select className="select select-bordered select-xs max-w-xs" onChange={(e) => handleOnChange(e)}>

            {options.map( item => (
                <option key={item.value} value={item.value}>{item.label}</option>
            ))}
        </select>
    )
}

export default SelectField

import React from 'react'
import Main from './main/Main'
import SideBar from './sidebar/SideBar'

function MainLayout({ children }) {
    return (
        <div className=' flex'>
            <SideBar />
            <Main children={children} />
        </div>
    )
}

export default MainLayout
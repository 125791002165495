import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react'
// import dateFormat from 'dateformat';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { FiMinusCircle, FiStar, FiUser } from 'react-icons/fi';
import { useAuthContext } from '../../hooks/useAuthContext';
import MainLayout from '../../components/layouts/MainLayout';
import Hostels from './Hostels';
import { NumericFormat } from 'react-number-format';
import toast, { Toaster } from 'react-hot-toast';

function ApprovedHostels() {
  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hostelDetails, setHostelDetails] = useState([]);
  const [hostelImages, setHostelImages] = useState([]);

  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState();


  const [isEmpty, setIsEmpty] = useState(false);

  const { user } = useAuthContext();

  // Hostel Images converted to an array of objects  with sc and thumbnail
  let imageArray = [];
  if (hostelImages) {
    hostelImages.map((img) => {
      return imageArray.push(
        {
          src: `${resourceURL}${img.name}`,
          thumbnail: `${resourceURL}${img.name}`
        }
      )
    })
  }

  // Get total number of Approved Hostels
  const { data: totalApprovedHostels, isLoading: TisLoading, isError: TisError, refetch: Trefetch } = useQuery(["TotalApprovedHostels"], () => {
    return axios.get(`${baseURL}admin/rooms?hostel_state=approved`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  // Fetch approved hostel data with pagination
  const { data: allHostels, isLoading, isError, refetch } = useQuery(["AllHostels", page], () => {
    return axios.get(`${baseURL}admin/rooms?page=${page}&hostel_state=approved`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });


  // Mark hostel as featured hostel
  const { mutate: markFeatured, } = useMutation({
    mutationFn: (hostelId) => {
      return axios.post(`${baseURL}admin/rooms/features/create`, {
        "hostel_id": hostelId
      }, {
        headers: {
          'Authorization': `Bearer ${user}`
        }
      })
    },
    onSuccess: () => {
      toast.success("Hostel marked as featured hostel.");
    },
    onError: () => {
      toast.error("Something went wrong, please try again later.");
    }
  })

  // Remove hostel as featured hostel
  const { mutate: removeAsFeatured, } = useMutation({
    mutationFn: (hostelId) => {
      return axios.post(`${baseURL}admin/rooms/features/remove`, {
        "hostel_id":  hostelId
      }, {
        headers: {
          'Authorization': `Bearer ${user}`
        }
      })
    },
    onSuccess: () => {
      toast.success("Hostel removed as featured hostel.");
    },
    onError: () => {
      toast.error("Something went wrong, please try again later.");
    }
  })

  // Fetch featured hostels data 
  const { data: featureHos } = useQuery(["FeaturedHostels"], () => {
    return axios.get(`${baseURL}admin/rooms/features`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  const allFeaturedRooms = featureHos?.featuredHostels



  // get last page value
  const lastPage = allHostels?.last_page;

  // Show hostel details function
  const showHostelDetails = async (roomId) => {
    setLoading(true);
    setError(null);  

    await axios.get(`${baseURL}admin/rooms/${roomId}/show`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((response) => {
      setHostelDetails(response.data);
      setHostelImages(response.data.images);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      setError(error.message);
    })
  }



  // facilities
  const facilities = hostelDetails ? hostelDetails?.facilities : null;

  // Handle pagination
  const handlePagination = (direction) => {
    if (page < lastPage && direction === "next") {
      setPage(page + 1);

    }

    if (direction === "prev" && page > 1) {
      setPage(page - 1);

    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }

  // Handle page position

  const handleGotoPage = () => {
    if (pageInput && pageInput <= lastPage) {
      setPage(pageInput);
    }
  }

  return (
    <>
      <MainLayout>
        <Hostels />
        {/* List of hostels */}

        {totalApprovedHostels?.total > 0 && totalApprovedHostels?.total ? <div className='flex'>
          <span className='bg-green-50 text-green-900 rounded-md text-sm font-semibold py-2 px-4 mb-5'>
            {`${totalApprovedHostels?.total} approved hostels in total.`}
          </span>
        </div> : <div className='bg-gray-50 text-gray-50 rounded-md text-sm font-semibold py-2 px-4 mb-5 w-64'>.</div>
        }

        <div className="flex flex-wrap  gap-4">

          {allHostels?.data?.length > 0 ? allHostels?.data.map((rooms) => (
            <div key={rooms?.id} className="flex gap-3 rounded-lg max-w-md w-full min-h-6 h-28  bg-white shadow-md overflow-hidden">
              <img src={`${resourceURL}${rooms?.images[0]?.name}`} alt="room" className='h-28 w-28 object-cover' />
              <div className="py-3 w-full pr-4">
                <h3 className='text-gray-600 text-sm'>{rooms?.name}</h3>
                <span className='text-xs text-gray-500'>{rooms?.address?.substr(0, 40)}..., {rooms?.city?.name}</span>
                <div className='flex justify-between gap-2 mt-4 items-center text-center'>
                  <span className='font-semibold text-green-700 text-sm'><NumericFormat value={rooms?.price} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> / {rooms?.pay_duration === "Daily" ? "day" : "month"}</span>

                  <label htmlFor="my-modal-4"
                    className='bg-blue-700 text-white px-3 py-1 rounded text-sm font-medium cursor-pointer'
                    onClick={() => showHostelDetails(rooms?.id)}>
                    View
                  </label>
                </div>

              </div>
            </div>
          )) : (<> {!isLoading ? "No records " : ""}</>)}
        </div>

        {isLoading && <div className='text-center'>
          <CircularPreloader />
        </div>}

        {/* Pagination */}

        {allHostels && lastPage > 1 && <div className="flex gap-2 mt-6 items-center">
          {page !== 1 && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-40 disabled:cursor-not-allowed'
            disabled={page === 1}
            onClick={() => { handlePagination("prev") }}
          >
            Previous
          </button>}

          {page < lastPage && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            disabled={page === lastPage}
            onClick={() => { handlePagination("next") }}
          >
            Next
          </button>}

          <span className='text-gray-500 text-xs'>{`Page ${page} of ${lastPage}`}</span>

          <input type="text" placeholder="Page" className="input input-bordered input-sm w-16 "
            onChange={(e) => setPageInput(Math.ceil(e.target.value))}
          />

          <button className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            onClick={() => handleGotoPage()}
            disabled={pageInput > lastPage}
          >
            Go
          </button>
        </div>}



        {/* Hostel Details modal window */}
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
          <label className="modal-box w-11/12 max-w-5xl relative" htmlFor="">

            {!loading && hostelDetails && <>  <div className="flex gap-2 justify-between items-center">
              <div>
                <h3 className='text-lg font-medium text-gray-600'>{hostelDetails.name}</h3>
                <p className='text-gray-600 text-xs pb-1 pt-2 font-medium capitalize'>{`${hostelDetails.address}, `}</p>
                <p className='text-gray-500 text-xs pb-2 pt-1'>{hostelDetails?.university?.name}</p>
              </div>

              <div className='flex flex-col justify-center items-center gap-2'>
                <span className='font-semibold text-2xl text-gray-700'><NumericFormat value={hostelDetails?.price} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></span>
                <div>
                  {hostelDetails?.is_featured === "no" && <button className='bg-green-100 text-green-700 py-1 px-2 
                  rounded-md text-xs flex gap-1 items-center hover:bg-yellow-400 hover:text-yellow-700 transition-all' 
                  onClick={() => markFeatured(hostelDetails.id)}>
                    <FiStar className='text-yellow-600' />
                    <span>Mark as Featured</span>
                  </button>}

                  {hostelDetails?.is_featured === "yes" && <button className='bg-blue-100 text-blue-700 py-1 px-2 
                  rounded-md text-xs flex gap-1 items-center hover:bg-red-100 hover:text-red-600 transition-all'
                  onClick={() => 
                    allFeaturedRooms?.length > 5 ? removeAsFeatured(hostelDetails.id) : toast.error("You are not allowed to remove this hostel as featured hostels can't be less than 5")
                  }
                  >
                    <FiMinusCircle />
                    <span>Remove as Featured</span>
                  </button>}

                </div>
              </div>
            </div>

              {/* Image slider goes here */}
              <div className='pt-3'>
                <Carousel images={imageArray} className="setSlider" style={{ height: 500 }} />
              </div>

              <div className="bg-green-100 text-sm text-green-600 font-bold py-1 px-3 rounded-md mt-6 mb-4 inline-block">Host Details</div>
              <div className='flex gap-4'>
                <div className="rounded-full h-16 w-16 bg-slate-300 flex items-center justify-center">
                  {hostelDetails?.host_data?.avatar === "users/default.png" ?
                    (<FiUser className='items-center text-white' size="1.2rem" />) :
                    (<img src={`${resourceURL}${hostelDetails?.host_data?.avatar}`} alt="" className='rounded-full h-16 w-16 object-cover' />
                    )}
                </div>

                <div className="flex flex-col">
                  <div className='flex gap-2'>
                    <p className='text-gray-500'>{hostelDetails?.host_data?.fName}</p>
                    <p className='text-gray-500'>{hostelDetails?.host_data?.lName}</p>
                  </div>
                  <span className='text-xs text-gray-500'>{hostelDetails?.host_data?.email}</span>
                  <span className='text-xs'>{hostelDetails?.host_data?.mobile}</span>

                </div>
              </div>

              <div className="bg-green-100 text-green-600 font-bold py-1 px-3 rounded-md mt-6 mb-4 inline-flex">Facilities</div>
              <div className='flex gap-3 flex-wrap'>
                {facilities?.split(',').map((facility, index) => {
                  return (<span key={index} className="bg-slate-100 text-gray-500 py-1 px-3 text-sm rounded capitalize">{facility}</span>)
                })}
              </div>

              <div className="bg-green-600 text-green-100 font-bold py-1 px-3 rounded-md mt-6 mb-4 inline-flex">Other Informations</div>

              <div className='flex'>
                <div className="w-1/4 p-2">
                  <div className="flex flex-col  bg-green-50 p-5">
                    <span className='text-xs text-green-800'>Avaliability</span>
                    <div className="bg-green-200 text-green-800 rounded px-3 py-1  mt-3 capitalize">{hostelDetails?.status}</div>
                  </div>

                </div>

                <div className="w-1/4 p-2">
                  <div className="flex flex-col  bg-blue-50 p-5">
                    <span className='text-xs text-blue-800'>Approval</span>
                    <div className="bg-blue-200 text-blue-800 rounded px-3 py-1  mt-3 capitalize">{hostelDetails?.hostel_state}</div>
                  </div>

                </div>

                <div className="w-1/4 p-2">
                  <div className="flex flex-col  bg-yellow-50 p-5">
                    <span className='text-xs text-yellow-800'>No of Bookings</span>
                    <div className="bg-yellow-200 text-yellow-800 rounded px-3 py-1  mt-3 capitalize">{hostelDetails?.bookings_count}</div>
                  </div>

                </div>

              </div>
            </>
            }

            {loading && <div className='text-center  w-full h-96 flex items-center justify-center'> <CircularPreloader /> </div>}

          </label>
        </label>
        <Toaster />
      </MainLayout>
    </>
  )
}

export default ApprovedHostels
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios'
import dateFormat from 'dateformat';
import { FiClock, FiDownload, FiEye, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';
import { useAuthContext } from '../../hooks/useAuthContext';
import MainLayout from '../../components/layouts/MainLayout';
import Users from './Users';
import { useNavigate } from 'react-router-dom';
import CsvDownload from 'react-csv-downloader';

function AllUsers() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const resourceURL = process.env.REACT_APP_RESOURCE_URL;

    const [userDetails, setUserDetails] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [page, setPage] = useState(1);
    const [pageInput, setPageInput] = useState();

    const { user } = useAuthContext();
    const navigate = useNavigate()

    useEffect(() => {
        if (!user) {
            navigate('/');
        }
    }, [user]);

    // Get total number of users
    const { data: totalUsers, isLoading: TisLoading, isError: TisError } = useQuery(["TotalUsers"], () => {
        return axios.get(`${baseURL}admin/users`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((res) => res.data)
    });

    // Get all users with pagination
    const { data: allUsers, isLoading, isError } = useQuery(["AllUsers", page], () => {
        return axios.get(`${baseURL}admin/users?page=${page}`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((res) => res.data)
    });

    // Export all users
    const { data: exportUsers, isLoading: EUisLoading, EUisError } = useQuery(["ExportUsers", page], () => {
        return axios.get(`${baseURL}admin/users/download`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((res) => res.data)
    });

    // Set coloumns for users export
    const columns = [
        { id: 'id', displayName: 'S/N' },
        { id: 'fName', displayName: 'First Name' },
        { id: 'lName', displayName: 'Last Name' },
        { id: 'email', displayName: 'Email Address' },
        { id: 'mobile', displayName: 'Phone Number' },
        { id: 'gender', displayName: 'Gender' },
        { id: 'balance', displayName: 'Balance' },
        { id: 'status', displayName: 'Status' },
        { id: 'role', displayName: 'Role' },
        { id: 'referral_code', displayName: 'Referral Code' },
        { id: 'referee', displayName: 'Referee' },
        { id: 'merchant_id', displayName: 'MerchnatId' },
        { id: 'created_at', displayName: 'Created At' },
    ];


    const showDetails = async (userId) => {
        setLoading(true);
        setError(null);

        await axios.get(`${baseURL}admin/users/${userId}/show`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((response) => {
            setUserDetails(response.data);
            setLoading(false);
        }
        ).catch((error) => {
            setLoading(false);
            setError(error.message);
        })
    }

    // get last page value
    const lastPage = allUsers?.last_page;


    // Handle pagination
    const handlePagination = (direction) => {
        if (page < lastPage && direction === "next") {
            setPage(page + 1);

        }

        if (direction === "prev" && page > 1) {
            setPage(page - 1);

        }

        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }

    // Handle page position

    const handleGotoPage = () => {
        if (pageInput && pageInput <= lastPage) {
            setPage(pageInput);
        }
    }


    return (
        <MainLayout>
            <Users />
            <div className="relative overflow-x-auto">
                {totalUsers?.total > 0 && totalUsers?.total ? <div className='flex flex-row justify-between gap-2'>
                    <span className='px-4 py-2 mb-5 text-sm font-semibold text-green-900 rounded-md bg-green-50'>
                        {`${totalUsers?.total} users in total.`}
                    </span>
                    <CsvDownload
                        filename='Users list export'
                        suffix={true}
                        extension='.csv'
                        columns={columns}
                        datas={exportUsers ? exportUsers?.user_data : ""}
                    >
                        <button
                            type='button'
                            className='font-medium text-white capitalize transition bg-blue-700 border-0 rounded-md btn btn-sm hover:bg-blue-600'

                        >
                            <FiDownload className='mr-2' />
                            Export CSV
                        </button>
                    </CsvDownload>

                </div> : <div className='w-48 px-4 py-2 mb-5 text-sm font-semibold rounded-md bg-gray-50 text-gray-50'>.</div>
                }

                <table className="table w-full table-zebra table-normal">

                    <tbody>
                        {allUsers?.data?.length > 0 && <tr className='font-semibold text-gray-500 uppercase'>
                            <td className='text-xs'>User Infomation</td>
                            <td className='text-xs'>Gender</td>
                            <td className='text-xs'>Phone Number</td>
                            <td className='text-xs'>Status</td>
                            <td className='text-xs'>Registration Date</td>
                            <td className='text-xs'>Action</td>
                        </tr>}

                        {allUsers?.data?.map((user, index) => (
                            <tr key={user.id} >
                                <td className='flex items-center gap-3'>
                                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-slate-600">
                                        {user.avatar === "default.png" ? (<FiUser className='items-center text-white' size="1.2rem" />) :
                                            (<img src={`${resourceURL}${user.avatar}`} alt="" className='object-cover w-10 h-10 rounded-full' />)}

                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className='text-gray-600 capitalize'>{user.fName} {user.lName}</p>
                                        <span className='text-sm text-gray-500'>{user.email}</span>
                                    </div>
                                </td>
                                <td className='text-gray-500'>{user.gender}</td>
                                <td className='text-gray-500'>{user.mobile}</td>
                                <td className='text-gray-500'>
                                    <span className={`px-2 py-1 text-white text-xs rounded-full ${user.status === "Active" ? "bg-green-700" : "bg-info"}`}>{user.status}</span>
                                </td>
                                <td className='text-gray-500'>{dateFormat(user.created_at, " mmm dS, yyyy,  h:MM TT")}</td>
                                <td>
                                    <label htmlFor="my-modal-4"
                                        onClick={() => showDetails(user.id)}
                                        className="flex items-center justify-center gap-2 text-green-700 cursor-pointer"
                                    >
                                        <FiEye size="1rem" className="" />  <span className='text-sm'>view</span>
                                    </label>
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>

                </table>

                {isLoading && <div className='mt-5 text-center'>
                    <CircularPreloader />
                </div>}

                {/* Pagination */}

                {allUsers && lastPage > 1 && <div className="flex items-center gap-2 mt-6">
                    {page !== 1 && <button
                        className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-40 disabled:cursor-not-allowed'
                        disabled={page === 1}
                        onClick={() => { handlePagination("prev") }}
                    >
                        Previous
                    </button>}

                    {page < lastPage && <button
                        className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
                        disabled={page === lastPage}
                        onClick={() => { handlePagination("next") }}
                    >
                        Next
                    </button>}

                    <span className='text-xs text-gray-500'>{`Page ${page} of ${lastPage}`}</span>

                    <input type="text" placeholder="Page" className="w-16 input input-bordered input-sm "
                        onChange={(e) => setPageInput(Math.ceil(e.target.value))}
                    />

                    <button className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
                        onClick={() => handleGotoPage()}
                        disabled={pageInput > lastPage}
                    >
                        Go
                    </button>
                </div>}



                {/* This is the user details modal details here */}
                <input type="checkbox" id="my-modal-4" className="modal-toggle" />
                <label htmlFor="my-modal-4" className="cursor-pointer modal">
                    <label className="relative modal-box" htmlFor="">

                        {!loading && userDetails ? (<div className="w-full h-auto px-4 py-2 bg-gray-100 rounded-xl min-h-12">

                            <div className="flex flex-row gap-5">
                                <div className="flex items-center justify-center w-20 h-20 mt-4 rounded-full bg-slate-400">
                                    {userDetails?.avatar === "default.png" ?
                                        (<FiUser className='items-center text-white' size="1.2rem" />) :
                                        (<img src={`${resourceURL}${userDetails.avatar}`} alt="" className='object-cover w-20 h-20 rounded-full' />
                                        )}
                                </div>

                                <div className="flex flex-col py-4">
                                    <span className='font-medium text-gray-600 capitalize'>
                                        {userDetails?.fName} {userDetails?.lName}
                                        <span className='px-1 ml-2 text-xs text-white rounded bg-success'>{userDetails?.gender}</span>
                                        <span className={`px-1 ml-2 text-white text-xs rounded ${userDetails?.status === "Active" ? "bg-success" : "bg-info"}`}>
                                            {userDetails?.status}
                                        </span>
                                    </span>
                                    <span className='flex items-center gap-2 text-gray-500'><FiMail className='' /> {userDetails?.email}</span>
                                    <span className='flex items-center gap-2 text-gray-500'><FiPhone /> {userDetails?.mobile}</span>
                                    <span className='flex items-center gap-2 text-xs text-gray-500'><FiClock /> {`Member since : ${dateFormat(userDetails.created_at, " mmm dS, yyyy,  h:MM TT")}`}</span>


                                </div>
                            </div>

                        </div>) : (<div className='text-center mt-7'> <CircularPreloader /> </div>)}


                    </label>
                </label>


            </div>
        </MainLayout>
    )
}

export default AllUsers
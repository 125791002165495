import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios'
import dateFormat from 'dateformat';
import toast, { Toaster } from 'react-hot-toast';
import { FiClock, FiEye, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';
import { useAuthContext } from '../../hooks/useAuthContext';
import MainLayout from '../../components/layouts/MainLayout';
import HelpCenter from './HelpCenter';

function PendingRequest() {
  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;

  const [requestDetails, setRequestDetails] = useState([]);
  const [requestID, setRequestID] = useState();

  const [resolveLoading, setResolveLoading] = useState(false);
  const [resolveError, setResolveError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState();

  const { user } = useAuthContext();

  // Get total number of pending request
  const { data: totalPendingRequest, isLoading: TisLoading, isError: TisError } = useQuery(["TotalPendingRequest"], () => {
    return axios.get(`${baseURL}admin/helps?status=pending`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });

  const { data: allRequest, isLoading, isError, refetch } = useQuery(["AllRequest", page], () => {
    return axios.get(`${baseURL}admin/helps?page=${page}&status=pending`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });


  // Get a request data and display it on a modal
  const getRequest = async (requestId) => {

    if (requestId) {

      setLoading(true);
      setError(null);
      await axios.get(`${baseURL}admin/helps/${requestId}/show`, {
        headers: {
          'Authorization': `Bearer ${user}`
        }
      }).then((response) => {
        setRequestDetails(response.data.helpData);
        setRequestID(requestId);
        setLoading(false);
      }
      ).catch((error) => {
        setLoading(false);
        setError(error.message);
      })
    }

  }


  // Resolve request function
  const handleResolveRequest = async (requestId) => {
    setResolveLoading(true);
    setResolveError(null);

    await axios.post(`${baseURL}admin/helps/${requestId}/update_status`,
      { "status": "resolved" },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user}`
        }
      }).then((response) => {
        if (response) {
          toast.success("Request resolved successfully.");
          document.getElementById('my-modal-4').checked = false;
          refetch();
        }
        setResolveLoading(false);
      }
      ).catch((error) => {
        setResolveLoading(false);
        setError(error.message);
        toast.error("Something went wrong!");
      })
  }

  // get last page value
  const lastPage = allRequest?.last_page;


  // Handle pagination
  const handlePagination = (direction) => {
    if (page < lastPage && direction === "next") {
      setPage(page + 1);

    }

    if (direction === "prev" && page > 1) {
      setPage(page - 1);

    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }

  // Handle page position

  const handleGotoPage = () => {
    if (pageInput && pageInput <= lastPage) {
      setPage(pageInput);
    }
  }



  return (
    <MainLayout>
      <HelpCenter />
      <div className="overflow-x-auto relative">

        {totalPendingRequest?.total > 0 && totalPendingRequest?.total ? <div className='flex'>
          <span className='bg-red-50 text-red-700 rounded-md text-sm font-semibold py-2 px-4 mb-5'>
            {`You have ${totalPendingRequest?.total} pending requests.`}
          </span>
        </div> : <div className='bg-gray-50 text-gray-50 rounded-md text-sm font-semibold py-2 px-4 mb-5 w-48'>.</div>
        }

        <table className="table table-zebra w-full table-normal">

          <tbody>

            {allRequest?.data?.length > 0 && <tr className='font-semibold uppercase text-gray-500'>
              <td className='text-xs'>User Infomation</td>
              <td className='text-xs'>Subject</td>
              <td className='text-xs'>Message</td>
              <td className='text-xs'>Request Time</td>
              <td className='text-xs'>Status</td>
              <td className='text-xs'>Action</td>
            </tr>}

            {allRequest?.data?.length > 0 ? allRequest?.data?.map((help, index) => (
              <tr key={help.id} >
                <td className='flex gap-3 items-center'>
                  <div className="rounded-full h-10 w-10 bg-slate-600 flex items-center justify-center">
                    {help?.user?.avatar === "users/default.png" ?
                      (<FiUser className='items-center text-white' size="1.2rem" />) :
                      (<img src={`${resourceURL}${help?.user?.avatar}`} alt="" className='rounded-full h-10 w-10 object-cover' />
                      )}

                  </div>
                  <div className="flex flex-col gap-1">
                    <p className='text-gray-600 capitalize'>{help?.user?.fName} {help?.user?.lName}</p>
                    <span className='text-gray-500 text-sm'>{help?.user?.email}</span>
                    <span className='text-gray-500 text-xs'>{help?.user?.mobile}</span>
                  </div>
                </td>
                <td className='text-gray-500'>{help?.subject?.substr(0, 40)} ...</td>
                <td className='text-gray-500'>{help?.message?.substr(0, 40)} ...</td>
                <td className='text-gray-500'>{dateFormat(help?.created_at, " mmm dS, yyyy,  h:MM TT")}</td>
                <td className='text-gray-500'>
                  <span className={`px-2 py-1 text-white text-xs rounded-full ${help?.status === "resolved" ? "bg-green-700" : "bg-info"}`}>{help?.status}</span>
                </td>

                <td>
                  <label htmlFor="my-modal-4"
                    onClick={() => getRequest(help?.id)}
                    className="cursor-pointer text-green-700 flex gap-2 justify-center items-center"
                  >
                    <FiEye size="1rem" className="" />  <span className='text-sm'>view</span>
                  </label>
                </td>
              </tr>
            )) : (<> {!isLoading ? "No records " : ""}</>)
            }
          </tbody>

        </table>

        {isLoading && <div className='text-center mt-5'>
          <CircularPreloader />
        </div>}

        {/* Pagination */}

        {allRequest && lastPage > 1 && <div className="flex gap-2 mt-6 items-center">
          {page !== 1 && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-40 disabled:cursor-not-allowed'
            disabled={page === 1}
            onClick={() => { handlePagination("prev") }}
          >
            Previous
          </button>}

          {page < lastPage && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            disabled={page === lastPage}
            onClick={() => { handlePagination("next") }}
          >
            Next
          </button>}

          <span className='text-gray-500 text-xs'>{`Page ${page} of ${lastPage}`}</span>

          <input type="text" placeholder="Page" className="input input-bordered input-sm w-16 "
            onChange={(e) => setPageInput(Math.ceil(e.target.value))}
          />

          <button className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            onClick={() => handleGotoPage()}
            disabled={pageInput > lastPage}
          >
            Go
          </button>
        </div>}



        {/* This is the user details modal details here */}
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
          <label className="modal-box relative" htmlFor="">

            {!loading && requestDetails ? (<div className='flex flex-col gap-5'>
              <div className="rounded-xl bg-gray-100 min-h-12 h-auto w-full px-4 py-2">

                <div className="flex flex-row gap-5">
                  <div className="h-20 w-20 rounded-full bg-slate-400 mt-4 flex items-center justify-center">
                    {requestDetails?.user?.avatar === "users/default.png" ?
                      (<FiUser className='items-center text-white' size="1.2rem" />) :
                      (<img src={`${resourceURL}${requestDetails?.user?.avatar}`} alt="" className='h-20 w-20 rounded-full object-cover' />
                      )}
                  </div>

                  <div className="py-4 flex flex-col">
                    <span className='capitalize font-medium text-gray-600'>
                      {requestDetails?.user?.fName} {requestDetails?.user?.lName}
                      <span className='bg-success text-white px-1 rounded ml-2 text-xs'>{requestDetails?.user?.gender}</span>
                      <span className={`px-1 ml-2 text-white text-xs rounded ${requestDetails?.user?.status === "Active" ? "bg-success" : "bg-info"}`}>
                        {requestDetails?.user?.status}
                      </span>
                    </span>
                    <span className='text-gray-500 flex items-center gap-2'><FiMail className='' /> {requestDetails?.user?.email}</span>
                    <span className='text-gray-500 flex items-center gap-2'><FiPhone /> {requestDetails?.user?.mobile}</span>
                    <span className='text-gray-500 flex items-center gap-2 text-xs'><FiClock /> {`Member since : ${dateFormat(requestDetails?.user?.created_at, " mmm dS, yyyy,  h:MM TT")}`}</span>


                  </div>
                </div>

              </div>

              <div className="rounded-xl bg-gray-100 min-h-12 h-auto w-full px-4 py-4">
                <div className="flex justify-between items-center">
                  <p className='text-xs uppercase py-3 font-semibold text-gray-500'>Request Time</p>
                  <p className='text-gray-600 text-sm font-semibold'>{dateFormat(requestDetails?.created_at, " mmm dS, yyyy,  h:MM TT")}</p>
                </div>

                <p className='text-xs uppercase py-3 font-semibold text-green-500'>Subject</p>
                <p className='text-md text-gray-600'>{requestDetails?.subject}</p>
                <p className='text-xs uppercase py-3 font-semibold text-green-500'>Message</p>
                <p className='text-gray-600'>{requestDetails?.message}</p>


                <button className='d-inline bg-green-600 hover:bg-green-500 transition text-white 
                px-3 py-1 rounded text-sm font-medium cursor-pointer mt-5'
                  onClick={() => handleResolveRequest(requestID)}
                >
                  Mark as resolved
                </button>
              </div>




            </div>) : (<div className='text-center mt-7'> <CircularPreloader /> </div>)}


          </label>
        </label>

        <Toaster />
      </div>


    </MainLayout>
  )
}

export default PendingRequest
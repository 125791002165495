import React, { useState } from 'react'
import MainLayout from '../../components/layouts/MainLayout'
import { FiArrowLeft, FiCalendar, FiCreditCard, FiEye, FiFileText, FiLayers, FiPhone, FiUser, FiUserCheck } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthContext } from '../../hooks/useAuthContext';
import dateFormat from 'dateformat';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader'
import { NumericFormat } from 'react-number-format'


const MerchantView = () => {

  const { id } = useParams();
  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;

  const { user } = useAuthContext();

  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [leadDetails, setLeadDetails] = useState(null);

  // get all list of agents
  const { data: merchantDetails, isLoading, isError } = useQuery(["MerchantDetails", page], () => {
    return axios.get(`${baseURL}admin/merchant/${id}/show`, {
      headers: { 'Authorization': `Bearer ${user}` }
    }).then((res) => res.data)
  });

  // Get lead details and display it on a modal
  const getMerchantDetails = async (leadId) => {
    if (leadId) {
      setLoading(true);
      setError(null);
      await axios.get(`${baseURL}admin/agents/leads/${leadId}/show`, {
        headers: {
          'Authorization': `Bearer ${user}`
        }
      }).then((response) => {
        setLeadDetails(response.data);
        setLoading(false);
      }
      ).catch((error) => {
        setLoading(false);
        setError(error.message);
      })
    }

  }

  // get last page value
  const lastPage = merchantDetails?.leads?.last_page;

  // Handle pagination
  const handlePagination = (direction) => {
    if (page < lastPage && direction === "next") {
      setPage(page + 1);

    }

    if (direction === "prev" && page > 1) {
      setPage(page - 1);

    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }

  // Handle page position
  const handleGotoPage = () => {
    if (pageInput && pageInput <= lastPage) {
      setPage(pageInput);
    }
  }

  return (
    <MainLayout>
      <div className='flex items-center justify-between mb-6'>
        <Link className='text-[14px] font-semibold text-gray-600 flex items-center gap-2' to="/merchants">
          <FiArrowLeft className='text-md' />
          <span>Go back</span>
        </Link>
      </div>

      <div className="w-full min-h-[83vh] h-full  bg-white rounded-md py-5 px-5">
        {isLoading &&
          <div className="flex items-center justify-center w-full h-[83vh] min-h-full">
            <CircularPreloader />
          </div>}

        {!isLoading && <>
          {/* User details */}
          <div className="flex flex-row items-center gap-3 ">
            <div className="flex gap-[10px] items-center">
              <div className="flex items-center justify-center w-16 h-16 bg-blue-200 rounded-full">

                {merchantDetails?.logo === "default.png" ?
                  (<FiUser className='items-center text-white' size="1.2rem" />) :
                  (<img src={`${resourceURL}${merchantDetails?.logo}`} alt="" className='object-cover w-16 h-16 rounded-full' />
                  )}

              </div>
              <div className="pr-9">
                <p className='text-sm font-semibold text-gray-500'>{merchantDetails?.company}</p>
                <span className='text-xs font-medium text-gray-400'>{merchantDetails?.email}</span>
              </div>
            </div>

            <div className="flex flex-col gap-1 pl-9 pr-6 border-l-[1px] border-gray-200">
              <div className="flex items-center gap-3 text-gray-500 ">
                <FiPhone />
                <span className='text-sm'>Mobile : {merchantDetails?.mobile}</span>
              </div>

              <div className="flex items-center gap-3 text-gray-500">
                <FiUserCheck />
                <span className='text-sm'>Merchant ID : {merchantDetails?.merchant_id}</span>
              </div>

              <div className="flex items-center gap-3 text-gray-500">
                <FiCalendar />
                <span className='text-sm'>Bookings count : {merchantDetails?.merchant_bookings_count}</span>
              </div>
            </div>

            {merchantDetails?.bank !== null && <div className="flex flex-col gap-1 pr-6 pl-9  border-l-[1px] border-gray-200">

              <div className="flex items-center gap-3 text-gray-500">
                <FiCreditCard />
                <span className='text-sm'>Account Number : <span className='font-bold text-green-500'>{merchantDetails?.bank?.account_num}</span></span>
              </div>

              <div className="flex items-center gap-3 text-gray-500">
                <FiLayers />
                <span className='text-sm'>Bank Name : {merchantDetails?.bank?.name}</span>
              </div>

              <div className="flex items-center gap-3 text-gray-500">
                <FiFileText />
                <span className='text-sm'>Account Name : {merchantDetails?.bank?.account_name}</span>
              </div>
            </div>}


          </div>

          <div className="flex border-b-[1px] pb-4 border-gray-100 mt-12 gap-2 items-center">
            <span className='font-semibold text-gray-600'>Users from {merchantDetails?.company}</span>
            <div className='flex items-center justify-center px-2 py-1 text-sm font-semibold text-green-600 bg-green-100 rounded-md'
            >
              {merchantDetails?.user_count}
            </div>
          </div>

          {/* List of merchant collections */}
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                {merchantDetails?.user?.length > 0 && <tr className='text-xs text-gray-500'>
                  <th>User</th>
                  <th>Balance</th>
                  <th>Phone Number</th>
                  <th>Data Count</th>
                  <th>Registration Date</th>
                  <th>Referral Code</th>
                </tr>}
              </thead>
              <tbody>
                {merchantDetails?.user?.map((user) => (

                  <tr className='' key={user?.id}>
                    <td>
                      <div className="flex gap-[10px]">
                        <div className="flex items-center justify-center bg-gray-200 rounded-full w-11 h-11">
                          <span className='text-[12px] font-semibold text-gray-600 uppercase'>
                            {user?.fName?.slice(0, 1)}{user?.lName?.slice(0, 1)}
                          </span>
                        </div>
                        <div className="">
                          <p className='flex gap-1 text-sm font-semibold text-gray-500'>
                            <span className='capitalize'>{user?.fName}</span>
                            <span className='capitalize'>{user?.lName}</span>
                          </p>
                          <span className='text-xs font-medium text-gray-400'>{user?.email}</span>
                        </div>
                      </div>
                    </td>
                    <td><span className='text-sm font-medium text-gray-500'>
                      <NumericFormat value={user?.balance} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                    </span></td>
                    <td><span className='text-sm font-medium text-gray-500'>{user?.mobile}</span></td>
                    <td>
                      <span className='font-medium text-green-700 '>{user?.gender}</span>
                    </td>
                    <td><span className='text-sm font-medium text-gray-500'>{dateFormat(user?.created_at, " mmm dS, yyyy,  h:MM TT")}</span></td>
                    <td>
                      <span className='font-medium text-green-700 '>{user?.referral_code}</span>
                    </td>
                    {/* <td>
                      <label htmlFor="my-modal-4" className='flex items-center gap-2' onClick={() => getMerchantDetails(user?.id)}>
                        <FiEye size="1rem" className='text-gray-500' />
                        <span className='text-sm font-medium text-gray-500 cursor-pointer'>View</span>
                      </label>
                    </td> */}
                  </tr>

                ))}

              </tbody>
            </table>
          </div>

          {/* Transaction History */}
          {merchantDetails?.user?.length > 0 && merchantDetails?.withdrawal?.length > 0 && <>
            <div className="py-6 mt-8 text-sm font-medium text-gray-500 uppercase border-t border-gray-100">Withdrawal History</div>
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  {merchantDetails?.user?.length > 0 && <tr className='text-xs text-gray-500'>
                    <th>Merchant ID</th>
                    <th>Amount Requested</th>
                    <th>Request Date</th>
                    <th>Status</th>
                    <th>Approval Date</th>
                  </tr>}
                </thead>
                <tbody>
                  {merchantDetails?.withdrawal?.map((user) => (

                    <tr className='' key={user?.id}>
                      <td>
                        <span className='text-xs font-medium text-gray-400'>{user?.merchant_id}</span>
                      </td>

                      <td>
                        <span className='text-sm font-medium text-gray-500'>
                          <NumericFormat value={user?.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                        </span>
                      </td>

                      <td><span className='text-sm font-medium text-gray-500'>{dateFormat(user?.created_at, " mmm dS, yyyy,  h:MM TT")}</span></td>
                      <td>
                        <span className={`text-sm  py-1 px-2 rounded ${user?.status === "Approved" ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"}`}>{user?.status}</span>
                      </td>
                      <td><span className='text-sm font-medium text-gray-500'>{dateFormat(user?.updated_at, " mmm dS, yyyy,  h:MM TT")}</span></td>

                    </tr>

                  ))}

                </tbody>
              </table>
            </div>
          </>}

          {/* Empty state */}

          {merchantDetails?.user?.length < 1 && <div className="w-full flex flex-col justify-center items-center gap-5 min-h-[55vh]">
            <p className='text-xl font-semibold text-gray-500'>This merchant is yet to get a lead</p>

          </div>}

        </>}


        {/* Pagination */}

        {merchantDetails && lastPage > 1 && <div className="flex items-center gap-2 mt-6">
          {page !== 1 && <button
            className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-40 disabled:cursor-not-allowed'
            disabled={page === 1}
            onClick={() => { handlePagination("prev") }}
          >
            Previous
          </button>}

          {page < lastPage && <button
            className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
            disabled={page === lastPage}
            onClick={() => { handlePagination("next") }}
          >
            Next
          </button>}

          <span className='text-xs text-gray-500'>{`Page ${page} of ${lastPage}`}</span>

          <input type="text" placeholder="Page" className="w-16 input input-bordered input-sm "
            onChange={(e) => setPageInput(Math.ceil(e.target.value))}
          />

          <button className='px-2 py-1 text-sm font-medium text-white transition ease-in-out delay-150 bg-blue-700 rounded hover:bg-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
            onClick={() => handleGotoPage()}
            disabled={pageInput > lastPage}
          >
            Go
          </button>
        </div>}

      </div>

      {/* Leads details */}

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my-modal-4" className="modal-toggle" />
      <label htmlFor="my-modal-4" className="cursor-pointer modal">
        <label className="relative modal-box" htmlFor="">
          {!loading && <>
            <label htmlFor="my-modal-4" className="absolute btn btn-xs btn-circle right-5 top-5">✕</label>
            <h3 className="mb-6 text-lg font-bold">{leadDetails?.name} Details</h3>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>Full name</span>
              <span className='text-gray-600'>{leadDetails?.name}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>Email address</span>
              <span className='text-gray-600'>{leadDetails?.email}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>Age</span>
              <span className='text-gray-600'>{leadDetails?.age} years old</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>Gender</span>
              <span className='text-gray-600'>{leadDetails?.gender}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>Marital Status</span>
              <span className='text-gray-600'>{leadDetails?.marital_status}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>Occupation</span>
              <span className='text-gray-600'>{leadDetails?.occupation}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>State of Origin</span>
              <span className='text-gray-600'>{leadDetails?.home_state}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>City of residence</span>
              <span className='text-gray-600'>{leadDetails?.home_city}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>Destination city</span>
              <span className='text-gray-600'>{leadDetails?.destination_city}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3">
              <span className='text-gray-500'>Travel Frequency </span>
              <span className='text-gray-600'>{leadDetails?.travel_duration}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3 ">
              <span className='w-2/3 text-gray-500'>Have you ever spent on accommodation
                when you're travelling?
              </span>
              <span className='w-1/3 text-right text-gray-600'>{leadDetails?.spent_on_accommodation}</span>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-gray-100 py-3 ">
              <span className='w-2/3 text-gray-500'>How much would you spend on accommodation</span>
              <span className='w-1/3 text-right text-gray-600'>{leadDetails?.living_cost}</span>
            </div>

            <div className="flex items-center justify-between py-3 ">
              <span className='w-2/3 text-gray-500'>How do you pay for the accommodation?</span>
              <span className='w-1/3 text-right text-gray-600'>{leadDetails?.accommodation_payment_mode}</span>
            </div>

          </>}

          {loading && <div className='flex items-center justify-center w-full h-96'>
            <CircularPreloader />
          </div>}

        </label>
      </label>

    </MainLayout>
  )
}

export default MerchantView

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useRef, useState } from 'react'
// import dateFormat from 'dateformat';
import CircularPreloader from '../../components/molecules/preloaders/circular/CircularPreloader';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { FiUser } from 'react-icons/fi';
import toast, { Toaster } from 'react-hot-toast';
import { useAuthContext } from '../../hooks/useAuthContext';
import MainLayout from '../../components/layouts/MainLayout';
import Hostels from './Hostels';
import { NumericFormat } from 'react-number-format';


function PendingHostels() {
  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [approveLoading, setApproveLoading] = useState(false);
  const [approveError, setApproveError] = useState(false);

  const [disapproveLoading, setDisapproveLoading] = useState(false);
  const [disapproveError, setDisapproveError] = useState(false);
  const [disapproveBtn, setDisapproveBtn] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonList, setReasonList] = useState([]);


  const [getRoomID, setGetRoomID] = useState();

  const [hostelDetails, setHostelDetails] = useState([]);
  const [hostelImages, setHostelImages] = useState([]);

  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState();

  const reasonRef = useRef()

  const { user } = useAuthContext();


  // Hostel Images converted to an array of objects  with sc and thumbnail
  let imageArray = [];
  if (hostelImages) {
    hostelImages.map((img) => {
      return imageArray.push(
        {
          src: `${resourceURL}${img.name}`,
          thumbnail: `${resourceURL}${img.name}`
        }
      )
    })
  }


  // Get total number of disapproved Hostels
  const { data: totalPendingHostels, isLoading: TisLoading, isError: TisError, refetch: Trefetch } = useQuery(["TotalApprovedHostels"], () => {
    return axios.get(`${baseURL}admin/rooms?hostel_state=pending`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });


  // Fetch hostel data
  const { data: allHostels, isLoading, isError, refetch } = useQuery(["AllHostels", page], () => {
    return axios.get(`${baseURL}admin/rooms?page=${page}&hostel_state=pending`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  });



  // get last page value
  const lastPage = allHostels?.last_page;

  // Show hostel details function
  const showHostelDetails = async (roomId) => {
    setLoading(true);
    setError(null);

    await axios.get(`${baseURL}admin/rooms/${roomId}/edit`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((response) => {
      setHostelDetails(response.data.hostel);
      setReasonList(response.data.disapproval_reason);
      setHostelImages(response.data.hostel.images);
      setGetRoomID(roomId);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      setError(error.message);
    })
  }

  // Approved Hostels function

  const approveHostel = async (roomId) => {
    setApproveLoading(true);
    setApproveError(null);

    await axios.post(`${baseURL}admin/rooms/${roomId}/update`,
      { "hostel_state": "approved" },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user}`
        }
      }).then((response) => {
        if (response) {
          toast.success("Hostel approved successful");
          document.getElementById('my-modal-4').checked = false;
          refetch();
        }
        setApproveLoading(false);
      }
      ).catch((error) => {
        setApproveLoading(false);
        setError(error.message);
        toast.error("Something went wrong!");
      })
  }


  // Disapprove Hostels function
  const disapproveHostel = async (roomDisapproveId) => {
    setDisapproveLoading(true);
    setDisapproveError(null);

    await axios.post(`${baseURL}admin/rooms/${roomDisapproveId}/update`,
      {
        "hostel_state": "disapproved",
        "disapprove_reason": reason ? reason : "Badly taken images"
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user}`
        }
      }).then((response) => {
        if (response) {
          toast.success("Hostel disapproved");
          document.getElementById('my-modal-4').checked = false;
          refetch();
        }
        setDisapproveLoading(false);
      }
      ).catch((error) => {
        setDisapproveLoading(false);
        setDisapproveError(error.message);
        toast.error("Something went wrong!");
      })
  }

  const handleHostelDisapproval = () => {
    disapproveHostel(getRoomID);
    setDisapproveBtn(false);

  }

  // facilities
  const facilities = hostelDetails ? hostelDetails?.facilities : null;

  // Handle pagination
  const handlePagination = (direction) => {
    if (page < lastPage && direction === "next") {
      setPage(page + 1);

    }

    if (direction === "prev" && page > 1) {
      setPage(page - 1);

    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }

  // Handle page position

  const handleGotoPage = () => {
    if (pageInput && pageInput <= lastPage) {
      setPage(pageInput);
    }
  }

  return (
    <>
      <MainLayout>
        <Hostels />
        {/* List of hostels */}

        {totalPendingHostels?.total > 0 && totalPendingHostels?.total ? <div className='flex'>
          <span className='bg-green-50 text-green-900 rounded-md text-sm font-semibold py-2 px-4 mb-5'>
            {`${totalPendingHostels?.total} pending hostel(s) in total.`}
          </span>
        </div> : (<>{totalPendingHostels?.total > 0 &&
          <div className='bg-gray-50 text-gray-50 rounded-md text-sm font-semibold py-2 px-4 mb-5 w-64'>.</div>}
        </>)
        }

        <div className="flex flex-wrap  gap-4">

          {allHostels?.data?.length > 0 ? allHostels?.data.map((rooms) => (
            <div key={rooms?.id} className="flex gap-3 rounded-lg max-w-md w-full min-h-6 h-28  bg-white shadow-md overflow-hidden">
              <img src={`${resourceURL}${rooms?.images[0]?.name}`} alt="room" className='h-28 w-28 object-cover' />
              <div className="py-3 w-full pr-4">
                <h3 className='text-gray-600 text-sm'>{rooms?.name}</h3>
                <span className='text-xs text-gray-500'>{rooms?.address?.substr(0, 40)}..., {rooms?.city?.name}</span>
                <div className='flex justify-between gap-2 mt-4 items-center text-center'>
                  <span className='font-semibold text-green-700 text-sm'><NumericFormat value={rooms?.price} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> / {rooms?.pay_duration === "Daily" ? "day" : "month"}</span>

                  <label htmlFor="my-modal-4"
                    className='bg-blue-700 text-white px-3 py-1 rounded text-sm font-medium cursor-pointer'
                    onClick={() => { showHostelDetails(rooms?.id) }}>
                    View
                  </label>
                </div>

              </div>
            </div>
          )) : (<> {!isLoading ? <div className='text-center'>No pending hostels</div> : ""}</>)}
        </div>

        {isLoading && <div className='text-center'>
          <CircularPreloader />
        </div>}

        {/* Pagination */}

        {allHostels && lastPage > 1 && <div className="flex gap-2 mt-6 items-center">
          {page !== 1 && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-40 disabled:cursor-not-allowed'
            disabled={page === 1}
            onClick={() => { handlePagination("prev") }}
          >
            Previous
          </button>}

          {page < lastPage && <button
            className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded 
                    transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            disabled={page === lastPage}
            onClick={() => { handlePagination("next") }}
          >
            Next
          </button>}

          <span className='text-gray-500 text-xs'>{`Page ${page} of ${lastPage}`}</span>

          <input type="text" placeholder="Page" className="input input-bordered input-sm w-16 "
            onChange={(e) => setPageInput(Math.ceil(e.target.value))}
          />

          <button className='bg-blue-700 hover:bg-blue-500 text-white py-1 px-2 font-medium text-sm rounded transition ease-in-out delay-150 disabled:opacity-50 disabled:cursor-not-allowed'
            onClick={() => handleGotoPage()}
            disabled={pageInput > lastPage}
          >
            Go
          </button>
        </div>}



        {/* Hostel Details modal window */}
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
          <label className="modal-box w-11/12 max-w-5xl relative" htmlFor="">

            {!loading && hostelDetails && <>  <div className="flex gap-2 justify-between items-center">
              <div>
                <h3 className='text-lg font-medium text-gray-600'>{hostelDetails.name}</h3>
                <p className='text-gray-600 text-xs pb-1 pt-2 font-medium capitalize'>{`${hostelDetails.address}, `}</p>
                <p className='text-gray-500 text-xs pb-2 pt-1'>{hostelDetails?.university?.name}</p>
              </div>

              <div className='font-semibold text-2xl text-gray-700'>
                <NumericFormat value={hostelDetails?.price} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
              </div>
            </div>

              {/* Image slider goes here */}
              <Carousel images={imageArray} className="setSlider" style={{ height: 500 }} />

              <div className="bg-green-100 text-green-600 font-bold py-1 px-3 rounded-md mt-6 mb-4 inline-flex">Hostel Details</div>
              <div className='flex gap-4'>
                <div className="rounded-full h-16 w-16 bg-slate-300 flex items-center justify-center">
                  {hostelDetails?.host_data?.avatar === "users/default.png" ?
                    (<FiUser className='items-center text-white' size="1.2rem" />) :
                    (<img src={`${resourceURL}${hostelDetails?.host_data?.avatar}`} alt="" className='rounded-full h-16 w-16 object-cover' />
                    )}
                </div>
                <div className="flex flex-col">
                  <div className='flex gap-2'>
                    <p className='text-gray-500'>{hostelDetails?.host_data?.fName}</p>
                    <p className='text-gray-500'>{hostelDetails?.host_data?.lName}</p>
                  </div>
                  <span className='text-xs text-gray-500'>{hostelDetails?.host_data?.email}</span>
                  <span className='text-xs'>{hostelDetails?.host_data?.mobile}</span>

                </div>
              </div>

              <div className="bg-green-100 text-green-600 font-bold py-1 px-3 rounded-md mt-6 mb-4 inline-flex">Facilities</div>
              <div className='flex gap-3 flex-wrap'>
                {facilities?.split(',').map((facility, index) => {
                  return (<span key={index} className="bg-slate-100 text-gray-500 py-1 px-3 text-sm rounded capitalize">{facility}</span>)
                })}
              </div>

              <div className="bg-green-600 text-green-100 font-bold py-1 px-3 rounded-md mt-6 mb-4 inline-flex">Other Informations</div>

              <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>

                <div className="flex flex-col bg-white shadow-md rounded-md p-3">
                  <span className='text-xs text-green-800'>Avaliability</span>
                  <div className="bg-slate-100 text-slate-800 rounded px-2 py-1 mt-3 capitalize text-sm">{hostelDetails?.status}</div>
                </div>

                <div className="flex flex-col bg-white shadow-md rounded-md p-3">
                  <span className='text-xs text-blue-800'>Approval</span>
                  <div className="bg-slate-100 text-slate-800 rounded px-2 py-1 mt-3 capitalize text-sm">{hostelDetails?.hostel_state}</div>
                </div>

                <div className="flex flex-col bg-white shadow-md rounded-md p-3">
                  <span className='text-xs text-yellow-800'>No of Bookings</span>
                  <div className="bg-slate-100 text-slate-800 rounded px-2 py-1 mt-3 capitalize text-sm">{hostelDetails?.bookings_count}</div>
                </div>

              </div>

              <div className='flex gap-4'>
                <button
                  className='bg-emerald-600 hover:bg-emerald-500 transition text-white px-3 py-1 rounded text-sm font-medium cursor-pointer mt-7'
                  onClick={() => approveHostel(getRoomID)}
                >
                  Approve
                </button>
                <button
                  className='bg-red-600 hover:bg-red-500 transition text-white px-3 py-1 rounded text-sm font-medium cursor-pointer mt-7'
                  onClick={() => setDisapproveBtn(!disapproveBtn)}
                >
                  Disapprove Hostel
                </button>
              </div>

              {disapproveBtn && <>
                <div className='flex flex-col mt-5'>
                  <span className="text-gray-500 pb-3">Select disapproval reason</span>
                  <select className="select select-bordered max-w-xs" onChange={(e) => setReason(e.target.value)} defaultValue="Select reason">
                    <option value="">Select reason</option>
                    {reasonList?.map((reason, index) => (<option key={index} value={reason}>{reason}</option>))}
                  </select>
                </div>

                <button className='bg-red-600 hover:bg-red-500 transition text-white px-3 
                py-2 rounded text-sm font-medium cursor-pointer mt-5 disabled:bg-red-200 disabled:cursor-not-allowed'
                  onClick={() => handleHostelDisapproval()}
                  disabled={!reason}
                >
                  Disapprove
                </button>
              </>}
            </>
            }

            {loading && <div className='text-center  w-full h-96 flex items-center justify-center'> <CircularPreloader /> </div>}

          </label>
        </label>

        <Toaster />

      </MainLayout>
    </>
  )
}

export default PendingHostels
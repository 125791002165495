import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

function Bookings() {

  const location = useLocation();

  return (
    <div className="">
      <ul className='flex gap-4 text-gray-500 mb-7 htabs'>
        <li><NavLink to="/bookings" className={location.pathname === "/bookings"? "nav_link" : "text-gray-800"}>All Bookings</NavLink></li>
        <li><NavLink to="/bookings/active" className="nav_link">Active</NavLink></li>
        <li><NavLink to="/bookings/pending" className="nav_link">Pending</NavLink></li>
        <li><NavLink to="/bookings/closed" className="nav_link">Closed</NavLink></li>
      </ul>
    </div>
  )
}

export default Bookings
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

function Users() {

  const location = useLocation();

  return (
    <div className='w-full'>

      <div className="">
        <ul className='flex gap-4 text-gray-500 mb-7 htabs'>
          <li><NavLink to="/users" className={location.pathname === "/users" ? "nav_link" : "text-gray-800"}>All Users</NavLink></li>
          <li><NavLink to="/users/active" className="nav_link">Active</NavLink></li>
          <li><NavLink to="/users/inactive" className="nav_link">InActive</NavLink></li>
          <li><NavLink to="/users/merchants" className="nav_link">Merchants</NavLink></li>
        </ul>
      </div>

    </div>
  )
}

export default Users
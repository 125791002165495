import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';

function AuthGuard() {

    const { user } = useAuthContext();
    const location = useLocation();

    return (
        user ? <Outlet/>
            : <Navigate to="/" state={{from: location}} />

    )
}

export default AuthGuard